import React, { useState } from 'react';
import { ReactComponent as OpenMenuIcon } from "../../assets/option_menu_icon.svg";
import { ReactComponent as OptionDotIcon } from "../../assets/option_dot.svg";
import { ReactComponent as TrashCanIcon } from "../../assets/trash_can.svg";
import styled from 'styled-components';

const Root = styled.div`
    position: relative;
`

const Menu = styled.button`
    font-family: 'Optum Sans', Helvetica, Arial;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400; 
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    color: #0C55B8;
    cursor: pointer;
    `;

const Closer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    display: none;
    z-index: 1;

    &.show {
        display: block;
    }
`

const Options = styled.div`
    position: absolute;
    top: 24px;
    left: -48px;
    // right: -128px;

    display: flex;
    visibility: hidden;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid var(--neutral-20, #E5E5E6);
    background: var(--neutral-0, #FFF);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: visibility 0s .5s, opacity .25s linear, z-index 0s .3s;
    z-index: -2;

    &.show {
        opacity: 1;
        visibility: visible;
        transition: right .25s, opacity .25s linear, z-index 0s 0s;
        top: 24px;
        z-index: 2;
    }
`

const Group = styled.div`
    color: var(--neutral-70, #6D6F70);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    padding: 0 8px;
`

const Option = styled.div`
    display: flex;
    padding: 0 8px;
    gap: 12px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: var(--neutral-90, #323334);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    white-space: nowrap;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid transparent;
    cursor: pointer;

    & svg {
        visibility: hidden;    
    }

    &.selected svg {
        visibility: visible;
    }

    &:hover {
      border-radius: 4px;
      border: 1px solid #224AA0;
      background: #EEF4FF;
    }14790

    &.delete {
      gap: 8px;

      & svg {
        visibility: visible;
      }
    }
`

const HR = styled.div`
    width: 100%;
    height: 1px;
    background: #CBCCCD;
    margin: 8px;
`

function OptionMenu({ row, data, actions, actionChanged }) {
    const [isOpen, setIsOpen] = useState(false);

    actionChanged = (actionChanged instanceof Function)
        ? actionChanged
        : () => { };

    const handleOpenMenuClick = () => {
        setIsOpen(true);
    }

    const handleClose = (e) => {
        setIsOpen(false);
        e.preventDefault();
        e.stopPropagation();

    }

    const handleActionChange = (row, value, selected) => {
        actionChanged(row, selected ? 0 : value);
        setIsOpen(false);
    }

    return (
        <Root>
            <Menu onClick={handleOpenMenuClick}>
                <OpenMenuIcon />
                Options
            </Menu>

            <Closer
                className={isOpen && 'show'}
                onClick={handleClose}
            />

            <Options className={isOpen && 'show'}>
                <Group>
                    Mark as
                </Group>
                <Option
                    className={(data?.action?.toLowerCase().startsWith('re')) ? 'selected' : ''}
                    onClick={() => handleActionChange(row, 2, data?.action?.toLowerCase().startsWith('re'))}
                >
                    <OptionDotIcon />
                    {actions[2]}
                </Option>
                <Option
                    className={(data?.action?.toLowerCase().startsWith('no')) ? 'selected' : ''}
                    onClick={() => handleActionChange(row, 1, data?.action?.toLowerCase().startsWith('no'))}
                >
                    <OptionDotIcon />
                    {actions[1]}
                </Option>

                {/* 
                <HR />
                <Option className='delete'>
                    <TrashCanIcon />
                    Delete
                </Option> 
                */}
            </Options>

        </Root >
    );
}

export default OptionMenu;
