const getDemoFlag = () => {    
    let providerDetails = JSON.parse(window.sessionStorage.getItem("providerDetails"));
    
    return (!providerDetails || providerDetails.role === "Demo User")
        ? "Y"
        : "N"
}

function format(date, options, separator) {
    function format(option) {
        let formatter = new Intl.DateTimeFormat('en', option);
        return formatter.format(date);
    }
    return options.map(format).join(separator);
}

const formatAsPercent = (value, errorValue) => {
    const number = parseFloat(value);
    if (isNaN(number)) return errorValue || '';

    return (value.includes('%'))
        ? number + '%'
        : (number * 100) + '%';        
}

const isLoginPage = () => {
    return window.location.pathname === "/";
}

export {
    formatAsPercent,
    isLoginPage,
    getDemoFlag,
    format
}