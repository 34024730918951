import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Popup from '../widgets/Popup.js';
import PopupGapAction from '../widgets/PopupGapAction.js';
import Comment from '../../assets/Comment.svg';
import { sendFeedback, updateGapStatus } from "../../services/Endpoints";
// import { Recommend } from '@mui/icons-material';

const Root = styled.div`
  user-select: none;
  position: absolute;
  top: 110px;
  bottom: 0px;
  left: 0px;
  right: 0px;  
`

const Header = styled.div`
  color: #002677;
  background-color: #FAF8F2;
  padding: 0 16px;
  line-height: 54px;
  font-size: 18px;
  font-weight: 700;
  border: solid 1px #E5E5E6;
  border-bottom-width: 2px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`
const Cards = styled.div`
  width: 100%;
  position: absolute;
  top: 50px;
  bottom: 0px;  
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 2px;
`

const Card = styled.div`
  width: 100%;
  border: solid 1px #E5E5E6;
  border-radius: 10px;
  margin-bottom: 20px;
`

const CardHeader = styled.div`
  display: flex;
  padding: 16px;
  color: #002677;
  font-size: 18px;
  font-weight: 700;
`

const CardDetails = styled.div`
  padding: 16px;
  background-color: #FAFAFA;
  border-top: none;
`

const Column1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
`

const Name = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #19191A;
  width: 70%;
`

const Title = styled.div`
  color: #002677;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
`

const Text = styled.p`
  color: #323334;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  &.clickable{
    cursor: pointer;
  }
`

const Button = styled.button`
  display: inline-block;
  background-color: transparent;
  font-family: Optum Sans, Helvetica, Arial;
  border: solid 1px #002677;
  border-radius: 999px;
  color: #002677;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`

const ClickedButton = styled.button`
  display: inline-block;
  background-color: #002677;
  border: solid 1px #002677;
  border-radius: 999px;
  color: #FFFFFF;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`

const Recommend = styled.span`
  display: inline-block;
  color: #007000;
  background: #EFF6EF;
  font-size: 14px;
  font-weight: 700;
  margin: 0 20px;
  padding: 8px 16px;
`

const LoadingCareGap = styled.div`
    width: 100%;
    height: 54px;
    margin: 1px 0;
    vertical-align: text-bottom;
    border-radius: 3px;
    cursor: progress; 
    background: 
      linear-gradient(0.25turn, transparent, #EEE, transparent),
      linear-gradient(#DDD, #DDD),
      radial-gradient(38px circle at 19px 19px, #DDD 20%, transparent 40%),
      linear-gradient(#DDD, #DDD);  
    background-repeat: no-repeat;
    background-size: 100% 250px, 100% 180px, 125px 100px, 125px 30px; 
    background-position: -500px 0, 0 0, 0px 190px, 50px 195px; 
    animation: loading 5s infinite;

    @keyframes loading {  
      to {
        background-position: 120px 0, 0 0, 0 190px, 0px 195px;
      }
    }
`

const FeedbackClick = styled.div`
  color: #0C55B8;
  font-family: Optum Sans, Helvetica, Arial;
  display: flex;
  alignt-items: center;
  gap: 4px;
  text-decoration: underline;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  width: 30%;
`

function CareGapsControl({ setNewLocation, careGapData, setEvidenceClicked, setFinishedDocument }) {
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupGapAction, setShowPopupGapAction] = useState(false);
  const [userAction, setUserAction] = useState("Close");
  const [gapData, setGapData] = useState(null);
  const [rerender, setRerender] = useState(false);

  const linkClick = (evidence, gap) => {
    let yCoord = 0;
    if (evidence.location.length > 0) {
      yCoord = (evidence.location[0].page_number) - 1;
      yCoord = yCoord * (11 * 96);
      if (evidence.location[0].polygon) {
        let evidenceSpot = evidence.location[0].polygon[0][1];
        evidenceSpot = evidenceSpot * 96;
        yCoord = yCoord + evidenceSpot;
      }
    } else {
      var evidenceString = String(evidence.text);
      var pageNumberChar = evidenceString.charAt(evidenceString.length - 2);
      var pageNumber = parseInt(pageNumberChar);
      yCoord = (pageNumber - 1) * (11 * 96 + 20);
    }

    evidence.careGap = gap;

    setNewLocation(yCoord);
    setEvidenceClicked({ ...evidence });
  }

  const handleFeedbackClick = (gap) => {
    setShowPopup(true);
    setGapData(gap);
  }

  const handleGapActionOpen = (gap) => {
    if (gap.gap_status === "" || gap.gap_status === "Close") {
      var recommended = gap.recommendation.toLowerCase();
      let user = JSON.parse(window.sessionStorage.getItem("UserOnehealthID"));
      if (user === null) {
        user = "localhost";
      }
      setUserAction("Leave Open");

      gap.gap_status = "Open";
      updateGapStatus(careGapData.file_id, gap.measure_id, gap.gap_status);

      let doneDocument = true;
      for (let i = 0; i < careGapData.care_gap_result.length; i++) {
        let status = (careGapData.care_gap_result[i].gap_status);
        if (status === "") {
          doneDocument = false;
        }
      }
      if (doneDocument) {
        setFinishedDocument(doneDocument);
      }

      if (recommended !== "not enough evidence") {
        setShowPopupGapAction(true);
        setGapData(gap);
      } else {
        var id = careGapData.file_id + "-" + gap.measure_id + "-Action";

        let data = {
          page_id: id,
          file_id: careGapData.file_id,
          care_gap_name: gap.measure_name,
          feedback_type: "Action",
          user: user,
          first_name: careGapData.first_name,
          last_name: careGapData.last_name,
          analysis: gap.analysis,
          evidence: gap.evidence,
          recommendation: gap.recommendation,
          actual: "Leave Open",
          comment: "No Feedback",
          thumbs_up_down: "No Feedback",
          category: "No Category"
        }

        sendFeedback(data);
      }
      setRerender(!rerender);
    } else {
      gap.gap_status = "";
      setFinishedDocument(false);
      setRerender(!rerender);
      updateGapStatus(careGapData.file_id, gap.measure_id, gap.gap_status);
    }
  }

  const handleGapActionClose = (gap) => {
    if (gap.gap_status === "" || gap.gap_status === "Open") {
      var recommended = gap.recommendation.toLowerCase();
      let user = JSON.parse(window.sessionStorage.getItem("UserOnehealthID"));
      if (user === null) {
        user = "localhost";
      }
      setUserAction("Close Gap");

      gap.gap_status = "Close";
      updateGapStatus(careGapData.file_id, gap.measure_id, gap.gap_status);

      let doneDocument = true;
      for (let i = 0; i < careGapData.care_gap_result.length; i++) {
        let status = (careGapData.care_gap_result[i].gap_status);
        if (status === "") {
          doneDocument = false;
        }
      }
      if (doneDocument) {
        setFinishedDocument(true);
      }

      if (recommended !== "close gap") {
        setShowPopupGapAction(true);
        setGapData(gap);
      } else {
        var id = careGapData.file_id + "-" + gap.measure_id + "-Action";

        let data = {
          page_id: id,
          file_id: careGapData.file_id,
          care_gap_name: gap.measure_name,
          feedback_type: "Action",
          user: user,
          first_name: careGapData.first_name,
          last_name: careGapData.last_name,
          analysis: gap.analysis,
          evidence: gap.evidence,
          recommendation: gap.recommendation,
          actual: "Close Gap",
          comment: "No Feedback",
          thumbs_up_down: "No Feedback",
          category: "No Category"
        }

        sendFeedback(data);
      }
      setRerender(!rerender);
    } else {
      gap.gap_status = "";
      setFinishedDocument(false);
      setRerender(!rerender);
      updateGapStatus(careGapData.file_id, gap.measure_id, gap.gap_status);
    }
  }

  return (
    <Root>

      <Popup Popup showing={ showPopup } setShowing={setShowPopup} gapData={gapData} careGapData={careGapData}/>
      <PopupGapAction showing={ showPopupGapAction } setShowing={setShowPopupGapAction} userAction={userAction} gapData={gapData} careGapData={careGapData}/>
      <Cards>
        {
          careGapData
            ? careGapData.care_gap_result
              .map((gap, i) => {
                return (
                  <Card key={gap.measure_name + i}>
                    <CardHeader>
                      <Name>
                        {gap.measure_name + " (" + gap.measure_id + ")"}
                      </Name>
                      <FeedbackClick onClick={() => { handleFeedbackClick(gap) }}>
                        <img style={{ height: '22px' }} src={Comment} alt="Comment" />
                        Leave Feedback
                      </FeedbackClick>
                    </CardHeader>

                    <CardDetails >

                      <Title>
                        Analysis
                      </Title>
                      <Text>
                         {typeof gap.analysis !== 'object' ?
                          gap.analysis :
                          Object.entries(gap.analysis).map(([key, value], index) => (  
                            <div key={index}>  
                              <label>  
                                {key}: {value}  
                              </label>  
                            </div>  
                          ))  
                          
                          }  
                      </Text>

                      < Title >
                        Evidence
                      </Title>
                      <Text className='clickable'>
                        {
                          Array.isArray(gap.evidence)
                            ?
                            gap.evidence.map((evidence, i) => {
                              return <li
                                onClick={() => { linkClick(evidence, gap.measure_name) }}
                                key={evidence + i}
                              // title={"Highlighting " + (evidence?.boxes?.length || "nothing") + " in document"}
                              >
                                {evidence.text}
                              </li>
                            })
                            : gap.evidence.text
                        }
                      </Text>
                      <Text>
                        {(gap.gap_status === "Close") && <ClickedButton onClick={() => { handleGapActionClose(gap) }}>
                          Close
                        </ClickedButton>}
                        {(gap.gap_status !== "Close") && <Button onClick={() => { handleGapActionClose(gap) }}>
                          Close
                        </Button>}
                        {
                          gap.recommendation.toLowerCase() === 'close gap'
                            ? <Recommend>Recommended</Recommend>
                            : null
                        }
                      </Text>
                      <Text>
                        {(gap.gap_status === "Open") && <ClickedButton onClick={() => { handleGapActionOpen(gap) }}>
                          Leave <span style={{ textTransform: "lowercase" }}>open</span>
                        </ClickedButton>}
                        {(gap.gap_status !== "Open") && <Button onClick={() => { handleGapActionOpen(gap) }}>
                          Leave <span style={{ textTransform: "lowercase" }}>open</span>
                        </Button>}
                        {
                          gap.recommendation.toLowerCase() === 'not enough evidence'
                            ? <Recommend>Recommended</Recommend>
                            : null
                        }
                      </Text>
                    </CardDetails>
                  </Card>
                )
              })
            :
            // 
            <LoadingCareGap />
        }

      </Cards>
    </Root >
  );
}

export default CareGapsControl;