import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CompletedPanel from "./Archived/Index";
import FailedPanel from "./Failed/Index";
import Header from "./widgets/Header";
import ManageUserAccess from "./ManageUserAccess/ManageUserAccess";
import PatientManagePanel from "./patient/Index";
import SettingsPanel from "./Settings/Index";
import WorkflowPanel from "./Workflow/Index";
import LoginPage from "./Login/index";
import styled from "styled-components";
import PDFPanel from "./Workflow/PDFPanel";
import AnalyticsReport from "./Reports/AnalyticsReport";
import UserLogs from "./UserLogs/UserLogs";
import { Route, Routes, Navigate } from "react-router-dom";
import { getFailedCount } from "../services/Endpoints";
import { isLoginPage } from "../services/utils";
import { appInsights } from "../features/Logger/appinsights";
import { useLocation } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import { lockPatientWorkflow } from "../services/Endpoints";
import ProtectedRoute from "./ProtectedRoute";

const Root = styled.div`
  position: fixed;
  font-family: Optum Sans, Helvetica, Arial;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  min-width: 900px;
`;

const Body = styled.div`
  position: relative;
  font-family: Optum Sans, Helvetica, Arial;
  width: 100%;
  margin-top: 112px;
  overflow: visible;
  transition: margin-left 0.25s;

  &.hidden {
    margin-left: 0px;
  }

  &.open {
    margin-left: 322px;
  }

  &.closed {
    margin-left: 84px;
  }
`;

var failedDocInterval;

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const isUserLogsPage = location.pathname.includes("/Userlogs") || location.pathname.includes("/Users");
  const token = window.sessionStorage.getItem("token");
  const user = window.sessionStorage.getItem("UserOnehealthID");
  const [failedCount, setFailedCount] = useState(0);
  const [logggedIn, setLoggedIn] = useState(token && user);
  const providerDetails = JSON.parse(window.sessionStorage.getItem("providerDetails")) || {};

  const cleanup = () => {
    return new Promise(async (resolve) => {
      const fileID = window.sessionStorage.getItem("workflowPanelFileID");
      const currentUser = JSON.parse(window.sessionStorage.getItem("workflowPanelCurrentUser"));
      if (fileID && currentUser && JSON.parse(window.sessionStorage.getItem("apiCalledOnEnter"))) {
        await lockPatientWorkflow(fileID, false, currentUser, (response) => {
          if (response) {
            console.log(response);
            window.sessionStorage.setItem("inReview", JSON.stringify(false));
            window.sessionStorage.setItem("apiCalledOnEnter", JSON.stringify(false));
            resolve();
          } else {
            console.log("Error in unlocking patient");
            resolve();
          }
        });
      } else {
        resolve();
      }
    });
  };

  useEffect(() => {
    if (!logggedIn)
      navigate('/')
  }, []);

  useEffect(() => {
    if (!logggedIn) {
      clearInterval(failedDocInterval);
      return;
    }

    console.log("SETTING INTERVAL")
    // Update failed doc counter
    getFailedCount(setFailedCount);

    // Then check for updates every 60 second
    // failedDocInterval = setInterval(() => { getFailedCount(setFailedCount); }, 60000)

    return () => clearInterval(failedDocInterval);
  }, [logggedIn])

  useEffect(() => {
    const interval = setInterval(() => {
      const userData = JSON.parse(
        window.sessionStorage.getItem("providerDetails")
      );
      if (userData !== null)
        activeTracking(userData.id, userData.role);
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Get the token from storage or state  
    const token = getCookie('Bearer');
    const signedToken = getCookie('SignedToken');
    const handleTokenExpiry = async () => {
      await cleanup();
      handleLogout();
    };

    const setExpiryTimeout = (decodedToken) => {
      const currentTime = Date.now() / 1000;
      const timeUntilExpiration = (decodedToken.exp - currentTime) * 1000;
      const cleanupTimeout = timeUntilExpiration - 30000;

      if (cleanupTimeout > 0) {
        const timeoutId = setTimeout(handleTokenExpiry, cleanupTimeout);
        return () => clearTimeout(timeoutId);
      } else {
        handleTokenExpiry();
      }
    };

    if (signedToken) {
      const decode_signedToken = jwtDecode(signedToken);
      setExpiryTimeout(decode_signedToken);
    }

    if (token) {
      const decoded = jwtDecode(token);
      setExpiryTimeout(decoded);
    }
  }, [navigate]);

  function getCookie(name) {
    let cookieValue = "";
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const activeTracking = (id, role) => {
    appInsights.trackEvent({
      name: "customLogs",
      properties: {
        userid: id,
        type: "active",
        file: "AppLayout",
        role: role,
      },
    });
  };

  let timer;

  const resetTimer = () => {
    clearTimeout(timer);

    timer = setTimeout(handleLogout, 15 * 60 * 1000); // 15 minutes in milliseconds
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      resetTimer();
      const events = [
        "load",
        "mousemove",
        "mousedown",
        "mouseup",
        "click",
        "mouseover",
        "scroll",
        "keypress",
      ];

      const resetTimerOnEvent = () => {
        resetTimer();
      };

      events.forEach((event) => {
        window.addEventListener(event, resetTimerOnEvent);
      });

      return () => {
        // Cleanup the event listeners when the component unmounts
        events.forEach((event) => {
          window.removeEventListener(event, resetTimerOnEvent);
        });
        clearTimeout(timer);
      };
    }
  }, [location, timer, resetTimer]);


  const handleLogout = async (event) => {
    if (location.pathname !== "/") {
      await cleanup();
      logoutTracking();
      const token = window.sessionStorage.getItem("token")
      window.sessionStorage.clear();
      const logoutUrl = "https://" + process.env.REACT_APP_logout_url + "?id_token_hint=" + token + '&post_logout_redirect_uri=' + process.env.REACT_APP_OHID_LogoutURLRedirect + '&state=' + Date.now();
      if (token === null) {
        window.location.replace(window.location.origin);
      }
      // console.log("Logout: ", logoutUrl);
      else window.location.replace(logoutUrl);
    }
  };

  const logoutTracking = () => {
    const userData = JSON.parse(
      window.sessionStorage.getItem("providerDetails")
    );
    appInsights.trackEvent({
      name: "customLogs",
      properties: {
        userid: userData.id,
        type: "logout",
        file: "App",
        role: userData.role,
      },
    });
  };

  const clearPatientListFilter = () => {
    window.sessionStorage.setItem("GroupFilter", '');
  }

  useEffect(() => {
    if (window.location.pathname.toLowerCase().includes('patientlist') || window.location.pathname.toLowerCase().includes('workflow')) return;

    clearPatientListFilter();
  })

  const userRole = providerDetails.role;

  return (
    <Root>
      <Body style={{ overflowY: isUserLogsPage ? "auto" : "visible" }}>
        {logggedIn ? (
          <Routes>
            <Route exact path="/" element={<LoginPage setLoggedIn={setLoggedIn} />} />
            <Route
              exact path="/Patientlist/*"
              element={
                <ProtectedRoute allowedRoles={["Devops Admin", "Demo User", "Quality Coordinator"]} userRole={userRole}>
                  <PatientManagePanel />
                </ProtectedRoute>
              }
            />
            <Route
              exact path="/workflow/*"
              element={
                <ProtectedRoute allowedRoles={["Devops Admin", "Demo User", "Quality Coordinator"]} userRole={userRole}>
                  <WorkflowPanel />
                </ProtectedRoute>
              }
            />
            <Route
              exact path="/archived/*"
              element={
                <ProtectedRoute allowedRoles={["Devops Admin", "Demo User", "Quality Coordinator"]} userRole={userRole}>
                  <CompletedPanel />
                </ProtectedRoute>
              }
            />
            <Route
              exact path="/failed/*"
              element={
                <ProtectedRoute allowedRoles={["Devops Admin", "Demo User", "Quality Coordinator"]} userRole={userRole}>
                  <FailedPanel setFailedCount={setFailedCount} />
                </ProtectedRoute>
              }
            />
            <Route
              exact path="/Users/*"
              element={
                <ProtectedRoute allowedRoles={["Devops Admin"]} userRole={userRole}>
                  <ManageUserAccess />
                </ProtectedRoute>
              }
            />
            <Route
              exact path="/Reports/*"
              element={
                <ProtectedRoute allowedRoles={["Devops Admin", "Client Manager"]} userRole={userRole}>
                  <AnalyticsReport />
                </ProtectedRoute>
              }
            />
            <Route
              exact path="/Userlogs/*"
              element={
                <ProtectedRoute allowedRoles={["Devops Admin", "Client Manager"]} userRole={userRole}>
                  <UserLogs />
                </ProtectedRoute>
              }
            />
            <Route
              exact path="/Pdfpanel/*"
              element={
                Object.keys(providerDetails).length !== 0 && <PDFPanel />
              }
            />
            <Route
              exact path="/Setting/*"
              element={
                Object.keys(providerDetails).length !== 0 && <SettingsPanel />
              }
            />
            <Route exact path="*" element={<Navigate to="/Patientlist" />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<LoginPage setLoggedIn={setLoggedIn} />} />
          </Routes>
        )}
      </Body>
      {isLoginPage() ? <></> : <Header userDetails={providerDetails} />}
    </Root>
  );
}

export default App;
