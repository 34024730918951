import { useEffect, useState } from "react";
import { lockPatientWorkflow } from "../../services/Endpoints";
import { appInsights } from "../../features/Logger/appinsights";
import { ReactComponent as ExpandIcon } from "../../assets/menu_expand_icon.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ProfileIcon } from "../../assets/ProfileIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import styled from "styled-components";

const Root = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 40px;
  font-family: Optum Sans, Helvetica, Arial !important;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  box-sizing: border-box;
  transition: width 0.25s;
  overflow: visible;
`;

const MenuCloser = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    z-index: 5;
    cursor: pointer;
`

const MenuItem = styled.button`
    position: relative;
    color: #323334;
    background-color: #FAFAFA;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Optum Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;

    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;

    margin-bottom: 5px;
    border-radius: 4px;
    border: 3px solid ${props => props.active ? '#0C55B8' : 'transparent'};

    &:hover {
        border: 3px solid ${props => props.suppresshover ? 'transpaent' : '#0C55B8'};
    }

    & svg.profileIcon {
        position: relative;
        margin-right: 4px;
    }

    & svg.open {
        transform: rotate(180deg);
    }
`;

const SubMenu = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid var(--neutral-50, #929496);
    background: var(--neutral-0, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(25, 25, 26, 0.16);
    z-index: 10;
`

const SubMenuItem = styled.div`
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    box-sizing: border-box;

    color: var(--neutral-90, #323334);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Optum Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;    
    width: max-content;
    min-width: 100%;
    border: none;
    background: transparent;
    cursor: pointer;

    &:hover {
        padding: 9px 13px;
        border: 3px solid #0C55B8;
    }
    
    &.selected {    
        border-left: 3px solid #0C55B8;
        background: #E9F1FF;
        padding-left: 13px;
    }

    &.noselect {
        &:hover {
            border: 3px solid transparent;
        }
    }
`

const Selection = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    bottom: -8px;
    background-color: #0C55B8;
    display: ${props => props.show ? 'block' : 'none'};
`

function MenuPanel({ userDetails }) {
    const [menuOpen, setMenuOpen] = useState('');
    const [location, setLocation] = useState('patientlist');
    const navigate = useNavigate();

    const navigateTo = (location) => {
        location = location.toLowerCase();
        setMenuOpen('');
        setLocation(location);
        navigate(location);
    }

    const openMenu = (menu) => {
        menuOpen
            ? setMenuOpen('')
            : setMenuOpen(menu);
    }

    const atLocation = (location) => {
        if (!location) return false;

        return window.location.pathname.toLowerCase().includes(location.toLowerCase());
    }

    const cleanup = () => {
        return new Promise(async (resolve) => {
            const fileID = window.sessionStorage.getItem("workflowPanelFileID");
            const currentUser = JSON.parse(window.sessionStorage.getItem("workflowPanelCurrentUser"));
            if (fileID && currentUser && JSON.parse(window.sessionStorage.getItem("apiCalledOnEnter"))) {
                await lockPatientWorkflow(fileID, false, currentUser, (response) => {
                    if (response) {
                        console.log(response);
                        window.sessionStorage.setItem("inReview", JSON.stringify(false));
                        window.sessionStorage.setItem("apiCalledOnEnter", JSON.stringify(false));
                        resolve();
                    } else {
                        console.log("Error in unlocking patient");
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    };

    const handleLogout = async (event) => {
        await cleanup();
        logoutTracking();
        setMenuOpen('');
        const token = window.sessionStorage.getItem("token")
        window.sessionStorage.clear();
        const logoutUrl = "https://" + process.env.REACT_APP_logout_url + "?id_token_hint=" + token + '&post_logout_redirect_uri=' + process.env.REACT_APP_OHID_LogoutURLRedirect + '&state=' + Date.now();
        if (token === null) {
            window.location.replace(window.location.origin);
        }
        // console.log("Logout: ", logoutUrl);
        else window.location.replace(logoutUrl);
    };

    const logoutTracking = () => {
        console.log("log timer");
        appInsights.trackEvent({
            name: "customLogs",
            properties: {
                userid: userDetails.id,
                type: "logout",
                file: "AppLayout",
                role: userDetails.role,
            },
        });
    };

    useEffect(() => {
        if (atLocation(location)) return;

        // user entered URL or clicked back/forward button;
        // So, set location to where we really are
        setLocation(['patientlist', 'failed', 'archived', 'users', 'reports', 'userlogs'].filter(loc => atLocation(loc))[0] || '')
    })

    return (
        <Root id='MenuPanel'>
            {
                menuOpen
                    ? <MenuCloser onClick={() => setMenuOpen('')} />
                    : <></>
            }

            {(userDetails.role === "Devops Admin" || userDetails.role === "Demo User" || userDetails.role === "Quality Coordinator") && (
                <>
                    <MenuItem
                        onClick={() => navigateTo("patientlist")}
                        suppresshover={location === 'patientlist'?'supress':''}
                    >
                        Patient list
                        <Selection show={atLocation('patientlist')?'true':''} />
                    </MenuItem>

                    <MenuItem
                        onClick={() => openMenu('manage')}
                        active={(menuOpen === 'manage')?'true':''}
                    >
                        Manage documents
                        <ExpandIcon className={menuOpen === 'manage' ? 'open' : ''} />
                        {
                            menuOpen === 'manage' &&
                            <SubMenu>
                                <SubMenuItem
                                    onClick={() => navigateTo("failed")}
                                    className={location === 'failed' ? 'selected' : ''}
                                >
                                    Failed documents
                                </SubMenuItem>
                                <SubMenuItem
                                    onClick={() => navigateTo("archived")}
                                    className={location === 'archived' ? 'selected' : ''}
                                >
                                    Archived documents
                                </SubMenuItem>
                            </SubMenu>
                        }
                    </MenuItem>
                </>
            )}

            {
                (userDetails.role === "Devops Admin" || userDetails.role === "Client Manager") && (
                    <MenuItem
                        onClick={() => openMenu('reporting')}
                        active={(menuOpen === 'reporting')?'true':''}
                    >
                        Reporting dashboard
                        <ExpandIcon className={menuOpen === 'reporting' ? 'open' : ''} />
                        {
                            menuOpen === 'reporting' &&
                            <SubMenu>
                                {(userDetails.role === "Devops Admin") && (
                                    <SubMenuItem
                                        onClick={() => navigateTo("users")}
                                        className={location === 'users' ? 'selected' : ''}
                                    >
                                        Manage user access
                                    </SubMenuItem>
                                )}
                                <SubMenuItem
                                    onClick={() => navigateTo("Reports")}
                                    className={location === 'reports' ? 'selected' : ''}
                                >
                                    Analytics report
                                </SubMenuItem>
                                <SubMenuItem
                                    onClick={() => navigateTo("Userlogs")}
                                    className={location === 'userlogs' ? 'selected' : ''}
                                >
                                    User logs
                                </SubMenuItem>
                            </SubMenu>
                        }
                    </MenuItem>
                )
            }

            <MenuItem
                onClick={() => openMenu('user')}
                active={(menuOpen === 'user')?'true':''}
            >
                <ProfileIcon className='profileIcon' />
                {userDetails.id || '[unknown]'}
                <ExpandIcon className={menuOpen === 'user' ? 'open' : ''} />
                {
                    menuOpen === 'user' &&
                    <SubMenu style={{ left: 'auto', right: 0}}>
                        <SubMenuItem className="noselect">User role: {userDetails.role || '[unknown]'}</SubMenuItem>
                        <SubMenuItem onClick={handleLogout}>
                            Logout
                            <LogoutIcon />
                        </SubMenuItem>
                    </SubMenu>
                }
            </MenuItem>

        </Root >
    );
}

export default MenuPanel;

