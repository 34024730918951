
const dateString = (millis) => {
    if (isNaN(parseInt(millis))) return '';

    const date = new Date(parseInt(millis));

    date.setHours(0);
    date.setMinutes(0);
    date.setMilliseconds(0);

    return date.toLocaleString("en-US", { dateStyle: "short" });
}

const prettyDate = (millis) => {
    const datePast = new Date(millis);
    const dateNow = new Date();

    var
        //  date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")),
        diff = ((dateNow.getTime() - datePast.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);

    // console.log("NOW : " + dateNow + "\nDB UTC:  " + datePast + "\nDiff: " + diff)

    if (isNaN(day_diff)) return '';
    if (day_diff < 0) return '';
    if (day_diff >= 31) return dateString(millis);

    return (day_diff <= 0)
        ? (
            ((diff < 60) && "just now") ||
            ((diff < 120) && "1 minute ago") ||
            ((diff < 3600) && (Math.floor(diff / 60) + " minutes ago")) ||
            ((diff < 7200) && "1 hour ago") ||
            ((diff < 86400) && (Math.floor(diff / 3600) + " hours ago")))
        : (
            ((day_diff === 1) && "Yesterday") ||
            ((day_diff < 7) && day_diff + " days ago") ||
            ((day_diff < 31) && Math.ceil(day_diff / 7)) + " weeks ago");
}

const convertToLocalTime = (timestamp) => {  
    let date = new Date(timestamp);    
    let localTime = date.toLocaleString();    
    return localTime;  
}

const LocalDate = (timestamp) => {  
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
}

const convertDBDateToMillis = (dbDate, isGMT) => {
    if (!dbDate) return;

    const dateMs = Date.parse(dbDate);
    const tzOffset = (new Date()).getTimezoneOffset();
    const dateUTC = new Date(dateMs - (tzOffset * (isGMT ? 60000 : 0)));

    return dateUTC.getTime();
};

export {
    prettyDate,
    convertDBDateToMillis,
    convertToLocalTime,
    LocalDate
}