import React, { useEffect } from 'react';
import { useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import PDFViewer from './PDFViewer';
import styled from 'styled-components';
import Previous from '../../assets/previous.svg';

const Root = styled.div`
    position: absolute;
    top: 25px;
    bottom: 0px;
    left: 0px;
    right: 50%;
    margin: 0 20px;
    `

const Header = styled.div`
    position: relative;
    box-sizing: border-box;
    height: 75px;
    display: flex;
    gap: 24px;
    `

const Title = styled.h1`
    color: #002677;
    font-size: 26px;
    font-weight: 700;
    `

const Member = styled.div`
    color: #323334;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;

    label {
      font-weight: 700;
    }
    `

const Demographics = styled.div`
    color: #323334;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    `

const PDFControl = styled.div`
    position: absolute;
    top: 110px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    overflow: visible;
`

const LoadingText = styled.div`
    display: inline-block;
    width: 120px;
    height: 1em;
    vertical-align: text-bottom;
    border-radius: 3px;
    cursor: progress; 
    background: 
      linear-gradient(0.25turn, transparent, #EEE, transparent),
      linear-gradient(#DDD, #DDD),
      radial-gradient(38px circle at 19px 19px, #DDD 20%, transparent 40%),
      linear-gradient(#DDD, #DDD);  
    background-repeat: no-repeat;
    background-size: 120px 250px, 120px 180px, 125px 100px, 125px 30px; 
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
    animation: loading 3s infinite;

    @keyframes loading {  
      to {
        background-position: 120px 0, 0 0, 0 190px, 0px 195px;
      }
    }
`



const SelectedDocumentText = styled.div`
    color: var(--neutral-90, #323334);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    margin-left: 16px;
    margin-top: 1.5px;
    margin-right: 16px;
`

const DocumentSelectedBox = styled.div`
    border-radius: 4px;
    border: 1px solid var(--neutral-20, #E5E5E6);
    background: var(--neutral-0, #FFF);
    min-width: 340px;
    height: 56px;
    margin-top: 16px;
`

const PreviousPage = styled.div`
  display: inline-block;
  color: #0C55B8;
  text-decoration: underline;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 5px;
  cursor: pointer;
`
const ExpandButton = styled.div`  
  position: absolute;  
  top: ${({ isExpanded }) => (isExpanded ? "-200px" : "15px")};  
  right: ${({ isExpanded }) => (isExpanded ? "-300px" : "10px")};  
  color: #0C55B8;  
  text-decoration: underline;  
  font-size: 16px;  
  font-weight: bold;  
  cursor: pointer;
  z-index: 1001
`

const ExpandButtonContainer = styled.div`  
  position: absolute;  
  top: 110px;  
  right: 10px;  
`

// *********************************************

function PDFPanel({ file, newLocation, careGapData, evidenceClicked }) {
  const [showPDF, setShowPDF] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [documentList, setDocumentList] = useState();
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedDocument(careGapData?.original_filename);
  }, [careGapData])

  useEffect(() => {

  }, [file])

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const pdfControlStyle = isExpanded
    ? { zIndex: 1000, position: "fixed", top: 0, right: 0, bottom: 0, left: 0 }
    : { zIndex: 0 };

  const patientListClick = () => {
    navigate("/Patientlist");
  };

  var documentNumber = "(" + documentList?.length + ")";

  const handlePageCountChange = (count) => {
    setPageCount(count);
  };

  return (
    <Root>
      <PreviousPage onClick={patientListClick}> <img src={Previous} alt="Previous" /> Back to Patient List</PreviousPage>
      <Header>
        <DocumentSelectedBox>
          <SelectedDocumentText>Doc Selected: {selectedDocument}</SelectedDocumentText>
          <SelectedDocumentText style={{ fontWeight: "normal" }}> {pageCount} pages</SelectedDocumentText>
        </DocumentSelectedBox>
      </Header>

      <ExpandButtonContainer>
        <ExpandButton onClick={toggleExpanded} isExpanded={isExpanded}>
          {isExpanded ? "Collapse" : "Expand"}
        </ExpandButton>
      </ExpandButtonContainer>
      <PDFControl style={pdfControlStyle}>
        <PDFViewer
          file={file}
          visible={showPDF}
          onClose={() => setShowPDF(false)}
          evidenceLocation={newLocation}
          careGapData={careGapData}
          evidenceClicked={evidenceClicked}
          onPageCountChange={handlePageCountChange}
        />
      </PDFControl>
    </Root>
  );
}

export default PDFPanel;
