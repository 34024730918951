import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import SortArrowIcon from "../../assets/sortupdown.svg";
import SearchIcon from "../../assets/search.svg";
import { getArchivedDocuments, getStoredPDF } from "../../services/Endpoints";
import { prettyDate, convertDBDateToMillis, convertToLocalTime } from "../../features/dates";
import { formatAsPercent } from "../../services/utils";
import Footer from "../widgets/Footer";

const Root = styled.div`
  padding: 40px 48px;
  padding-bottom: 0;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
`;

const Body = styled.div`
  min-height: 100%;
  padding-bottom: 58px;
  box-sizing: border-box;
  `

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  color: #001d5b;
  font-size: 26px;
  font-weight: 700;
  height: 40px;
  line-height: 32px;
  word-wrap: break-word;
`;

const Filter = styled.div`
  color: #001d5b;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  font-family: "Optum Sans", Helvetica, Arial;
`;

const PatientSearch = styled.input`
  color: #4b4d4f;
  width: 250px;
  padding: 0.5em 1em;
  border: solid 1px #cbcccd;
  border-radius: 100px;
  background-image: url(${SearchIcon});
  background-repeat: no-repeat;
  background-position: 97% center;
  font-family: "Optum Sans", Helvetica, Arial;
`;

const Count = styled.div`
  color: #4b4d4f;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
`;

const Table = styled.table`
  width: 100%;
  margin-top: 16px;
  border-spacing: 0;
  border-collapse: collapse;
`;

const THR = styled.tr`
  color: #002677;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;

  & th {
    border: solid 1px #cbcccd;
    padding-bottom: 11px;
    text-align: left;
    padding: 8px 5px;

    &.clickable div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
`;

const Loading = styled.td`
  font-size: 24px;
  text-align: center !important;
`;

const SortIcon = styled.div`
  display: inline-block;
  width: 10.3px;
  height: 15.8px;
  background-image: url(${SortArrowIcon});
  background-repeat: no-repeat;
  background-position: center center;

  &.reverse {
    transform: rotate(180deg);
  }
`;

const TBR = styled.tr`
  color: #323334;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;

  &.clickable {
    cursor: pointer;
  }

  &.shade {
    background-color: #fafafa;
  }

  &:hover {
    background-color: #fafaffFF;
  }

  & td {
    border: solid 1px #cbcccd;
    padding: 16px 5px;
    text-align: left;

    &.highlight {
      font-weight: 700;
      color: #196ecf;
    }

    &.incomplete {
      color: #ff0000;
    }

    &.complete {
      color: #027148;
    }
  }
`;

const TDText = styled.td``;

const TDNumb = styled.td``;

const TDDate = styled.td``;

const TDIcon = styled.td`
  display: flex;
  flex-direction: row;
  align-contents: center;
  gap: 5px;
`;

const LoadAnim = styled.div`
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background: transparent;
  overflow: hidden;

  & h1 {
    position: absolute;
    top: 0;    
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 64px;
    font-weight: 700;
    color: #000000AA;
  }
  `

// ***********************************************************

function ArchiveddPanel(props) {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [rawData, setRawData] = useState([]);
  const [columnsOrder, setColumnsOrder] = useState([...Array(10).fill(1)]);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const filteredData = updateFilteredData(searchValue, columnsOrder);

  function updateFilteredData(filter, orders) {
    if (!rawData) return { message: 'Error loading data' };
    if (!rawData?.length) return { message: 'Loading...' };
    if (rawData?.[0] === 'NODATA') return { message: 'No archived documents found' };

    let newData = filter
      ? rawData.filter((t) =>
        t.name.toLowerCase().includes(filter.toLowerCase())
      )
      : [...rawData];

    orders = orders || columnsOrder;

    for (let column = 0; column < orders.length; column++) {
      switch (column) {
        case 8:
          newData.sort((a, b) => {
            return (b.loaddate - a.loaddate) * columnsOrder[8];
          });
          break;
        default:
          break;
      }
    }

    return newData;
  }

  const handlePatientSearchChange = (e) => {
    updateFilteredData(e.target.value);
    setSearchValue(e.target.value);
  };

  const handleColumnClick = (column) => {
    let newColumnsOrder = [...columnsOrder];
    newColumnsOrder[column] = -newColumnsOrder[column];
    updateFilteredData(searchValue, newColumnsOrder);
    setColumnsOrder(newColumnsOrder);
  };

  const handleRowClick = (data) => {
    if (!data.archival_filename) return;

    setLoadingPDF(true);
    getStoredPDF(
      {
        container_name: "archivedcharts",
        file_id: data.archival_filename
      },
      (response) => {
        setLoadingPDF(false);
        if (response)
          window.open(URL.createObjectURL(response.data));
      }
    )
  }

  const setResponseAsRawData = (data) => {
    if (!Array.isArray(data)) return setRawData(null);  // API error

    console.log("RAW ARCHIVE DATA:", data)
    let newData = [];

    data.map((data) => {
      const fname = data.first_name || "";
      const lname = data.last_name || "";
      const gapsOpen = data.match_data?.measure_ids?.length || "";
      const gapsClosed = data.match_data?.measure_ids?.length || "";

      // TODO: Check for duplicate patient and merge if duplicate
      newData.push(
        {
          id: data.id || "",
          file_id: data.id || "",
          first_name: data.first_name || "",
          last_name: data.last_name || "",
          dob: prettyDate(convertDBDateToMillis(data.dob + 'T00:00:00')),
          pcp: data.pcp || "",
          original_filename: "original_file.pdf",
          file_load_date: data.file_load_date || '',
          archival_status: data.archival_status || '',
          archival_date: data.archival_date || '',
          archival_username: data.archival_username || '',
          archival_filename: data.archival_filename || '',
          status: data.status || '',
          group: data.group || '',
          num_pages: data.num_pages || '',
          loaded_by: data.loaded_by || '',
          demo_only: data.demo_only || 'N',
          match_data: data.match_data || {},

          name: fname + " " + lname,
          gaps: gapsOpen || "",
          match: formatAsPercent(data.match_data?.match_confidence),
          file_load_millis: convertDBDateToMillis(data.file_load_date, true)
        });
    });

    newData.length
      ? setRawData(newData)
      : setRawData(["NODATA"])
  };

  useEffect(() => {
    getArchivedDocuments(setResponseAsRawData);
  }, []);

  return (
    <Root>
      <Body>
        <Header>
          <Title>Archived Documents</Title>
          <Filter>
            <PatientSearch
              placeholder="Search for a patient"
              value={searchValue}
              onChange={handlePatientSearchChange}
            />
          </Filter>
        </Header>
        {/* <Count>{filteredData?.length || ""} Patients</Count> */}

        <Table>
          <thead>
            <THR>
              <th>Patient Name</th>
              <th>DOB</th>
              <th>Document Name</th>
              <th>Status</th>
              <th>Gaps Open</th>
              <th>Match%</th>
              <th>Group Name</th>
              <th className="clickable" onClick={() => handleColumnClick(8)}>
                <div>
                  Loaded
                  <SortIcon />
                </div>
              </th>
            </THR>
          </thead>

          <tbody>
            {
              filteredData?.length
                ?
                filteredData.map((data, i) => {
                  return (
                    <TBR
                      key={"doc" + i}
                      className={
                        ((i % 2) ? "clear" : "shade") +
                        ((data.archival_filename) ? ' clickable' : '')
                      }
                      onClick={() => handleRowClick(data)}
                    >
                      <td className='highlight'>{data.name}</td>
                      <td>{data.dob}</td>
                      <td>{data.archival_filename}</td>
                      <td>{data.status}</td>
                      <td>{data.gaps}</td>
                      <td>{data.match}</td>
                      <td>{data.group}</td>
                      <td>{convertToLocalTime(data.file_load_millis)}</td>
                    </TBR>
                  )
                })
                :
                <TBR>
                  <Loading colSpan={10}>
                    {filteredData.message}
                  </Loading>
                </TBR>
            }
          </tbody>
        </Table>
        {
          loadingPDF
            ?
            <LoadAnim>
              <h1>Loading PDF</h1>
              <svg version="1.1" id="L4" x="0px" y="0px" viewBox="-225 -30 500 500" enable-background="new 0 0 100 100" space="preserve">
                <circle fill="#000" stroke="none" cx="6" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1" />
                </circle>
                <circle fill="#000" stroke="none" cx="26" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2" />
                </circle>
                <circle fill="#000" stroke="none" cx="46" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3" />
                </circle>
              </svg>
            </LoadAnim>
            :
            <></>
        }
      </Body>
      <Footer/>
    </Root>
  );
}

export default ArchiveddPanel;
