import React, { useEffect, useState } from "react";
import styled from "styled-components";
import UploadButton from "../widgets/UploadButton";
import { useNavigate } from "react-router-dom";
import SortAscendingIcon from "../../assets/sort_ascending.svg";
import SortDecendingIcon from "../../assets/sort_descending.svg";
import SortUndefinedIcon from "../../assets/sort_undefined.svg";
import SearchIcon from "../../assets/search.svg";
import { ReactComponent as ErrorIcon } from "../../assets/error_outline.svg";
import { getActiveDocuments } from "../../services/Endpoints";
import { prettyDate, convertDBDateToMillis, LocalDate } from "../../features/dates";
import { formatAsPercent } from "../../services/utils";
import Footer from "../widgets/Footer";

const Root = styled.div`
  padding: 40px 48px;
  padding-bottom: 0;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
`;

const Body = styled.div`
  min-height: 100%;
  padding-bottom: 74px;
  box-sizing: border-box;
  `

const GroupNameHeader = styled.th`  
  width: 250px;
`;

const GroupDropdown = styled.select`  
  margin-left: 10px;  
  font-size: 14px;  
  border: 1px solid #cbcccd;  
  font-family: Optum Sans, Helvetica, Arial;
  border-radius: 4px;  
  background-color: white;  
  height: 24px;  
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px;
  margin: 0px;
  margin-bottom: 21px;
`;

const Title = styled.div`
  color: #001d5b;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  word-wrap: break-word;  
  margin-bottom: 8px;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

const PatientSearch = styled.input`
  color: #4b4d4f;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #323334;
  width: 250px;
  padding: 8px 12px;
  border: solid 1px #cbcccd;
  border-radius: 100px;
  background-image: url(${SearchIcon});
  background-repeat: no-repeat;
  background-position: 97% center;

  &::-ms-input-placeholder {
    color: #4B4D4F;
  }
  
  
  &::placeholder {
    color: #4B4D4F;
  }

  &:hover,
  &:focus {
    border-color: #002677;
    outline: none;
  }
`;

const Count = styled.div`
  color: #C40000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;

  & svg {
    position: relative;
    top: 4px;
    margin-right: 5px;
  }
`;

const Table = styled.table`
  width: 100%;
  margin-top: 16px;
  border-spacing: 0;
  border-collapse: collapse;
`;

const THR = styled.tr`
  color: #002677;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  word-wrap: break-word;

  & th {
    border: solid 1px #cbcccd;
    padding-bottom: 11px;
    text-align: left;
    padding: 12px 8px;
    font-weight: 700;
    Color: #323334;

    &.clickable div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      cursor: pointer;
    }
  }
`;

const Loading = styled.td`
  font-size: 24px;
  text-align: center !important;
`;

const SortIcon = styled.div`
  display: inline-block;
  width: 10.3px;
  height: 15.8px;
  background-image: url(${SortUndefinedIcon});
  background-repeat: no-repeat;
  background-position: center center;

  &.ascending {
    background-image: url(${SortAscendingIcon});
  }

  &.descending {
    background-image: url(${SortDecendingIcon});
  }

  &.reverse {
    transform: rotate(180deg);
  }
`;

const TBR = styled.tr`
  color: #323334;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #323334;
  word-wrap: break-word;
  cursor: pointer;

  &.shade {
    background-color: #fafafa;
  }

  &:hover {
    background-color: #F4F7FA;
  }

  & td {    
    border: solid 1px #cbcccd;
    padding: 12px 8px;
    text-align: left;

    &.titlecase {
      text-transform: capitalize;
    }

    &.highlight {
      font-weight: 700;
      color: #0C55B8;
    }

    &.incomplete {
      color: #ff0000;
    }

    &.complete {
      color: #027148;
    }
  }
`;

const TDText = styled.td``;

const TDNumb = styled.td``;

const TDDate = styled.td``;

const TDIcon = styled.td`
  display: flex;
  flex-direction: row;
  align-contents: center;
  gap: 5px;
`;

// ***********************************************************

function PatientManagement({ menuState }) {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [rawData, setRawData] = useState([]);
  const [columnsOrder, setColumnsOrder] = useState([...Array(10).fill(0)]);
  const [filteredData, setFilterData] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("all");

  const sortIconClass = ['descending', 'undefined', 'ascending']

  const handleGroupChange = (e) => {
    const groupFilter = e.target.value;

    window.sessionStorage.setItem("GroupFilter", groupFilter);

    setSelectedGroup(groupFilter);
    updateFilteredData(searchValue, columnsOrder, groupFilter);
  };

  function updateFilteredData(filter, orders, group) {
    if (!rawData) return setFilterData({ message: 'Error loading data' });
    if (!rawData?.length) return setFilterData({ message: 'Loading...' });
    if (rawData?.[0] === 'NODATA') return setFilterData({ message: 'No patient documents found' });

    let newData = rawData;

    if (filter) {
      newData = newData.filter((t) =>
        t.name.toLowerCase().includes(filter.toLowerCase())
      );
    }

    if (group && group !== "all") {
      newData = newData.filter((t) => t.group === group);
    }

    orders = orders || columnsOrder;

    for (let column = 0; column < orders.length; column++) {
      switch (column) {
        case 9:
          if (!orders) return; // 'descending' = -1, 'undefined'= 0, 'ascending'= 1]
          newData.sort((a, b) => {
            return (a.loaddate - b.loaddate) * orders[9];
          });
          break;
        default:
          break;
      }
    }

    return setFilterData(newData);
  }

  const handlePatientSearchChange = (e) => {
    updateFilteredData(e.target.value);
    setSearchValue(e.target.value);
  };

  const handleRowClick = (rowData) => {
    let patientData = {
      file_id: rowData?.fileId || "",
      patient_first_name: rowData?.fname || "",
      patient_last_name: rowData?.lname || "",
      DOB: rowData?.dob || "",
    };
    navigate("/Workflow", { state: { patientData: patientData, rowData: rowData } });
  };

  const handleColumnClick = (column) => {
    let newColumnsOrder = [...columnsOrder];
    newColumnsOrder[column] = (columnsOrder[column] === 0) ? 1 : (columnsOrder[column] * -1);
    updateFilteredData(searchValue, newColumnsOrder, selectedGroup);
    setColumnsOrder(newColumnsOrder);
  };

  const setResponseAsRawData = (data) => {
    if (!Array.isArray(data?.data)) return setRawData(null); // endpoint error

    console.log("RAW PATIENT DATA: ", data);
    let newData = [];
    let uniqueGroupNames = new Set();

    data.data.map((data) => {
      const fname = data?.first_name || "";
      const lname = data?.last_name || "";
      const gapsOpen = data?.match_data?.measure_ids?.length || "0";
      uniqueGroupNames.add(data.group || "unknown");

      // TODO: Check for duplicate patient and merge if duplicate
      newData.push({
        fileId: data?.file_id || "",
        fname: fname,
        lname: lname,
        name: (fname + " " + lname).toLowerCase(),
        dob: convertDBDateToMillis(data.dob + 'T00:00:00') || "",
        documents: [data.original_filename || "unknown"],
        provider: data.pcp || "unknown",
        group: data.group || "unknown",
        match: formatAsPercent(data.match_data?.match_confidence, data.match_data?.match_confidence),
        gaps: gapsOpen + ' open',
        loaddate: convertDBDateToMillis(data.file_load_date, true),
        status: data.status || "unknown",
        in_review: data.in_review,
        complete: false,
      });
    });
    setGroupNames(Array.from(uniqueGroupNames));

    newData.length
      ? setRawData(newData)
      : setRawData(["NODATA"])
  };

  useEffect(() => {
    getActiveDocuments(setResponseAsRawData);
  }, []);

  useEffect(() => {
    const groupFilter = window.sessionStorage.getItem("GroupFilter") || 'all';
    setSelectedGroup(groupFilter);
    updateFilteredData(searchValue, columnsOrder, groupFilter);

    // updateFilteredData();
  }, [rawData])

  return (
    <Root>
      <Body>
        <Header>
          <div>
            <Title>Patient List</Title>
            {
              filteredData.length
                ?
                <Count>
                  <ErrorIcon />
                  {filteredData?.length || ""} incomplete patients
                </Count>
                :
                <></>
            }
          </div>
          <Filter>
            <PatientSearch
              placeholder="Search for a patient"
              value={searchValue}
              onChange={handlePatientSearchChange}
            />
            <UploadButton />
          </Filter>
        </Header>
        <Table>
          <thead>
            <THR>
              <th>Patient Name</th>
              <th>DOB</th>
              <th>Gaps Open</th>
              <th>Match%</th>
              <GroupNameHeader>
                Group Name
                <GroupDropdown value={selectedGroup} onChange={handleGroupChange}>
                  <option value="all">All</option>
                  {
                    groupNames.map((groupName, index) => (
                      <option key={index} value={groupName}>
                        {groupName}
                      </option>
                    ))
                  }
                </GroupDropdown>
              </GroupNameHeader>
              <th className="clickable" onClick={() => handleColumnClick(9)}>
                <div>
                  Uploaded On
                  <SortIcon className={sortIconClass[columnsOrder[9] + 1]} />
                </div>
              </th>
              <th>In Review</th>
            </THR>
          </thead>

          <tbody>
            {filteredData.length ? (
              filteredData.map((data, i) => {
                return (
                  <TBR
                    key={"doc" + i}
                    className={i % 2 ? "clear" : "shade"}
                    onClick={() => handleRowClick(data)}
                  >
                    <td className="highlight titlecase">{data.name}</td>
                    <td>{prettyDate(data.dob)}</td>
                    <td>{data.gaps}</td>
                    <td>{data.match}</td>
                    <td>{data.group}</td>
                    <td>{LocalDate(data.loaddate)}</td>
                    <td>{data.in_review ? 'Yes' : 'No'}</td>
                  </TBR>
                );
              })
            ) : (
              <TBR>
                <Loading colSpan={10}>
                  {filteredData.message}
                </Loading>
              </TBR>
            )}
          </tbody>
        </Table>
      </Body>
      <Footer menuState={menuState} />
    </Root>
  );
}

export default PatientManagement;
