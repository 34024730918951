import styled from "styled-components";
import React, { useState, useRef } from 'react';
import { uploadChart } from "../../services/Endpoints";
import { appInsights } from "../../features/Logger/appinsights";
import Modal from "./UploadPopup";
import uploadIcon from "../../assets/upload.svg";

const Root = styled.div`

`

const Button = styled.button`
  padding-left: 56px;
  box-sizing: border-box;
  display: flex;
  color: white;
  font-family: Optum Sans, Helvetica, Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 8px 24px; 
  background: #002677; 
  border-radius: 999px; 
  border: none;
  overflow: hidden; 
  cursor: pointer;
  justify-content: center; 
  align-items: center; gap: 8px; 
  display: inline-flex;
  margin: 0px;

  &:hover {
    background-color: #001D5B;
  }
`;

function UploadButton(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const fileInput = useRef(null);
  const user = JSON.parse(window.sessionStorage.getItem("role"));

  const saveLogs = (id, role, file_name) => {
    appInsights.trackEvent({
      name: "customLogs",
      properties: {
        userid: id,
        type: "UploadDocs",
        file: "AppLayout",
        fileName: file_name,
        role: role,
      },
    });
  };



  const handleUpload = async () => {
    let filename = fileInput.current.files[0].name;
    let fileData = fileInput.current;
    console.log(fileData);
    console.log("hello");
    const file = fileData.files[0];
    console.log(file);
    // Create a FileReader object to read the file
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      uploadChart(reader.result, filename, (val) => {
        const userData = JSON.parse(
          window.sessionStorage.getItem("providerDetails")
        );
        saveLogs(userData.id, userData.role, filename);
        setModalOpen(true);
        setTimeout(() => setModalOpen(false), 8000);
      });
    };
  };

  if (user === null) {
    return (
      <Root>
        <div className='content-center'>
          <Button
            variant="outlined"
            style={{ borderRadius: "24px" }}
            onClick={() => fileInput.current.click()}>
            <img src={uploadIcon} alt="Upload Icon" style={{ width: '24px', height: '24px' }} />
            Upload document
          </Button>
          <input
            ref={fileInput}
            type="file"
            accept="application/pdf"
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
          <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
            Upload successful. File will begin processing. Processing may take up to an hour.
          </Modal>
        </div>
      </Root>
    );
  }
}

export default UploadButton;
