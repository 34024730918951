import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ErrorIcon } from "../../assets/error_outline.svg";
import { ReactComponent as OpenInNewIcon } from "../../assets/open_in_new.svg";
import { getFailedDocuments, updateFailedDocument, getStoredPDF } from "../../services/Endpoints";
import { LocalDate, convertDBDateToMillis, convertToLocalTime } from "../../features/dates";
import SortAscendingIcon from "../../assets/sort_ascending.svg";
import SortDecendingIcon from "../../assets/sort_descending.svg";
import SortUndefinedIcon from "../../assets/sort_undefined.svg";
import Footer from "../widgets/Footer";
import UploadButton from "../widgets/UploadButton";
import OptionMenu from "./OptionMenu";

const Root = styled.div`
  padding: 40px 48px;
  padding-bottom: 0;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
`;

const Body = styled.div`
  min-height: 100%;
  padding-bottom: 74px;
  box-sizing: border-box;
  `

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const Title = styled.div`
  color: #001d5b;
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  word-wrap: break-word;
  margin-bottom: 8px;
`;

const Count = styled.div`
  color: #C40000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;

  & svg {
    position: relative;
    top: 4px;
    margin-right: 4px;
  }
`;

const Table = styled.table`
  width: 100%;
  margin-top: 32px;
  border-spacing: 0;
  border-collapse: collapse;
`;

const THR = styled.tr`
  color: #323334;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;

  word-wrap: break-word;

  & th {
    border: solid 1px #cbcccd;
    text-align: left;
    padding: 12px 8px;

    & div.clickable {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      cursor: pointer;
    }
  }
`;

const Loading = styled.td`
  font-size: 24px;
  text-align: center !important;
`;

const SortIcon = styled.div`
  display: inline-block;
  width: 10.3px;
  height: 15.8px;
  background-image: url(${SortUndefinedIcon});
  background-repeat: no-repeat;
  background-position: center center;

  &.ascending {
    background-image: url(${SortAscendingIcon});
  }

  &.descending {
    background-image: url(${SortDecendingIcon});
  }

  &.reverse {
    transform: rotate(180deg);
  }
`;

const TBR = styled.tr`
  color: #323334;
  font-size: 14px;
  font-style: normal;
  word-wrap: break-word;

  &.shade {
    background-color: #fafafa;
  }

  &:hover {
    // background-color: #fafaffFF;
  }
  
  & td {
    border: solid 1px #cbcccd;
    padding: 12px 8px;
    text-align: left;
    line-height: 24px;

    &.alert {
      color: #C40000;
    }

    &.highlight {
      color: #196ecf;
      font-weight: 700;
    }

    & div.clickable {
      color: #0C55B8;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      
      & svg {
        min-width: 16px;
      }

      & a {
        cursor: pointer;
        margin-bottom: -2px;
        border-bottom: 2px solid transparent;

        &:hover {
          color: #003A8D;
          border-bottom: 2px solid #003A8D;
        }
      }
    }

    &.incomplete {
      color: #ff0000;
    }

    &.complete {
      color: #027148;
    }
  }
`;

const Select = styled.select`
  width: 100%;
  height: 36px;
  padding: 8px;
  border-radius: 5px;
`;

const TDText = styled.td``;

const TDNumb = styled.td``;

const TDDate = styled.td``;

const TDIcon = styled.td`
  display: flex;
  flex-direction: row;
  align-contents: center;
  gap: 5px;
`;

const LoadAnim = styled.div`
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background: transparent;
  overflow: hidden;

  & h1 {
    position: absolute;
    top: 0;    
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 64px;
    font-weight: 700;
    color: #000000AA;
  }
  `

// ***********************************************************

function FailedPanel({ setFailedCount }) {
  const navigate = useNavigate();
  const [columnsOrder, setColumnsOrder] = useState([0,0,-1,0,0]);
  const [rawData, setRawData] = useState([]);
  const [filteredData, setFilterData] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);


  const sortIconClass = ['descending', 'undefined', 'ascending']

  const actionMap = ["Select an option", "No action needed", "Reuploaded"];

  const updateFilteredData = (newColumnsOrder) => {
    if (!rawData) return setFilterData({ message: 'Error loading data' });
    if (!rawData?.length) return setFilterData({ message: 'Loading...' });
    if (rawData?.[0] === 'NODATA') return setFilterData({ message: 'No failed documents found' });

    let filteredData = [...rawData];

    newColumnsOrder = newColumnsOrder || columnsOrder;

    for (let column = 0; column < 3; column++) {
      if (newColumnsOrder[column] !== 0)
        switch (column) {
          case 0:
            filteredData.sort((a, b) => {
              return ('' + a.original_filename).localeCompare(b.original_filename) * newColumnsOrder[0];
            });
            break;
          case 1:
            filteredData.sort((a, b) => {
              return ('' + a.details).localeCompare(b.details) * newColumnsOrder[1];
            });
            break;
          case 2:
            filteredData.sort((a, b) => {
              return (a.processed_date - b.processed_date) * newColumnsOrder[2];
            });
            break;
          default:
            break;
        }
    }

    setFilterData(filteredData);
  }

  const getValueOfAction = (action) => {
    let value = actionMap.indexOf(action);

    if (value >= 0) return value;

    console.log("Found action with invalid value [" + action + "]. Setting value to '" + actionMap[0] + "'");
    return 0;
  }

  const saveRowData = (rowData) => {
    console.log("SAVING FAILED DOCUMENT: ", rowData)
    updateFailedDocument(rowData, (value) => { console.log(value ? "SAVE SUCESSFUL" : "SAVE FAILED") });
  }

  const handleActionChange = (row, value) => {
    if (filteredData.length < row) return;

    let newFilteredData = [...filteredData];
    newFilteredData[row].action_value = value;
    newFilteredData[row].action = actionMap[value];
    newFilteredData[row].username = JSON.parse(window.sessionStorage.getItem("oneHealthId"));;
    setFilterData(newFilteredData);
    setFailedCount((newFilteredData.filter(data => data.action?.toLowerCase().includes('select'))).length)

    saveRowData(newFilteredData[row])
  };

  const handleColumnClick = (column) => {
    let newColumnsOrder = [...Array(10).fill(0)];
    newColumnsOrder[column] = (columnsOrder[column] === 0) ? 1 : (columnsOrder[column] * -1);
    updateFilteredData(newColumnsOrder);
    setColumnsOrder(newColumnsOrder);
  };

  const handleFilenameClick = (data) => {
    if (!data.file_id) return;

    setLoadingPDF(true);
    getStoredPDF(
      {
        container_name: "failedcharts",
        file_id: data.file_id
      },
      (response) => {
        setLoadingPDF(false);
        if (response)
          window.open(URL.createObjectURL(response.data));
      }
    )
  }

  const setResponseAsRawData = (data) => {
    if (!Array.isArray(data)) return setRawData(null); // API error

    console.log("RAW FAILED DATA: ", data)

    let newData = [];

    data.map((data) => {
      console.log("DATE: ", data.processed_date)
      newData.push({
        file_id: data.file_id,
        document_score: data.document_score,
        demo_only: data.demo_only,
        original_filename: data.original_file_name,
        action: data.action,
        action_value: getValueOfAction(data.action),
        processed_date: convertDBDateToMillis(data.processed_date, true),
        failed_process: data.failed_process,
        details: data.details,
        unmatched: data.unmatched,
        matched: data.matched,
        confidence_score: data.confidence_score,
        document_status: data.document_status
      });
    });

    newData.length
      ? setRawData(newData)
      : setRawData(["NODATA"])
  };

  useEffect(() => {
    getFailedDocuments(setResponseAsRawData);
  }, []);

  useEffect(() => {
    updateFilteredData();
  }, [rawData])

  return (
    <Root>
      <Body>
        <Header>
          <div>
            <Title>Failed Documents</Title>
            {
              filteredData?.length
                ?
                <Count>
                  <ErrorIcon />
                  {filteredData?.length || ""} Failed
                </Count>
                :
                <></>
            }
          </div>

          <UploadButton />
        </Header>
        <Table>
          <thead>
            <THR>
              <th>
                <div className="clickable" onClick={() => handleColumnClick(0)}>
                  Document
                  <SortIcon className={sortIconClass[columnsOrder[0] + 1]} />
                </div>
              </th>
              <th>
                <div className="clickable" onClick={() => handleColumnClick(1)}>
                  Reason for failure
                  <SortIcon className={sortIconClass[columnsOrder[1] + 1]} />
                </div>
              </th>
              <th>
                <div className="clickable" onClick={() => handleColumnClick(2)}>
                  Processed on
                  <SortIcon className={sortIconClass[columnsOrder[2] + 1]} />
                </div>
              </th>
              <th>Unmatched data</th>
              <th>Actions</th>

            </THR>
          </thead>

          <tbody>
            {
              filteredData?.length
                ?
                filteredData.map((data, i) => {
                  return (
                    <TBR
                      key={"doc" + i}
                      className={(i % 2) ? "clear" : "shade"}
                    >
                      <td className='highlight'>
                        <div className="clickable">
                          <a onClick={() => handleFilenameClick(data)}>
                            {data.original_filename}
                          </a>
                          <OpenInNewIcon />
                        </div>
                      </td>
                      <td>{data.details}</td>
                      <td>{LocalDate(data.processed_date)}</td>
                      <td>{data.unmatched}</td>
                      <td>
                        <OptionMenu row={i} data={data} actions={actionMap} actionChanged={handleActionChange} />
                      </td>
                    </TBR>
                  )
                })
                :
                <TBR>
                  <Loading colSpan={10}>
                    {filteredData.message}
                  </Loading>
                </TBR>
            }
          </tbody>
        </Table>

        {
          loadingPDF
            ?
            <LoadAnim>
              <h1>Loading PDF</h1>
              <svg version="1.1" id="L4" x="0px" y="0px" viewBox="-225 -30 500 500" enable-background="new 0 0 100 100" space="preserve">
                <circle fill="#000" stroke="none" cx="6" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1" />
                </circle>
                <circle fill="#000" stroke="none" cx="26" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2" />
                </circle>
                <circle fill="#000" stroke="none" cx="46" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3" />
                </circle>
              </svg>
            </LoadAnim>
            :
            <></>
        }
      </Body>
      <Footer />
    </Root>
  );
}

export default FailedPanel;
