import React, { useState, useEffect } from 'react';
import CloseX from '../../assets/CloseX.svg'
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { updateArchiveData, uploadArchiveBlob } from '../../services/Endpoints';


const Root = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000070;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;

    cursor: pointer;

    z-index: 99999;

    display: ${props => props.showing?"block":"none"};
`

const Card = styled.div`
    width: 30%;
    padding: 16px 24px;
    gap: 16px;
    flex-wrap: wrap;
    border-radius: 12px;
    background: var(--modal-color-background-base, #FFF);
    /* modal/elevation/medium */
    box-shadow: 0px 4px 12px 0px rgba(25, 25, 26, 0.16);
    cursor: default;
    margin-top: 15%;
    margin-left: 30%
`

const Header = styled.div`
    display: flex;
    margin-left: 97%;
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;
`
const Footer = styled.div`
    margin-top: 34px;
    display: flex;
    flex-direction: row;
`

const Title = styled.div`
    color: var(--modal-color-text-heading, #002677);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 123.077% */
`

const SubtitleHolder = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const Subtitle = styled.div`
    color: #323334;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */;
    word-wrap: break-word;
`
const Subtext = styled.div`
    color: #323334;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`

const Button = styled.button`
    display: flex;
    background-color: #002677;
    border: solid 1px #002677;
    border-radius: 999px;
    padding: 8px 24px;
    font-size: 14px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    margin-bottom: 8px;
    margin-left: 24px;
    margin-right: 24px;
`

// ***********************************************

function Popup({ showing, setShowing, careGapData, newFileName}) {

    const navigate = useNavigate();

    const handleBackdropClick = (e) => {
        setShowing(false);
        document.getElementById('commentarea').value = "";
    }

    const handleCardClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const handleSend = () => {
      let user = JSON.parse(window.sessionStorage.getItem("UserOnehealthID"));
      if(user === null) {
        user = "localhost";
      }
      let data =
        {
          file_id: careGapData.file_id,
          archival_username: user,
          archival_status: "Y",
          archival_filename: newFileName
        }

      updateArchiveData(data);
      uploadArchiveBlob(careGapData.file_id, newFileName);
      navigate("/PatientList");
    }

    useEffect(()=>{
        setShowing(showing)
    }, [showing])

    return (
        <Root showing={ showing } onClick={ handleBackdropClick }>
            <Card onClick={ handleCardClick }>
                <Header>
                  <img src = {CloseX} alt="CloseX" onClick={handleBackdropClick}/>
                </Header>
                <Body>
                  <Title>Archive Document:</Title>
                  <SubtitleHolder>
                    <Subtitle>New File Name: {newFileName}</Subtitle>
                    <Subtext>Archiving this document will return you to the incomplete patient list</Subtext>
                  </SubtitleHolder>
                </Body>
                <Footer>
                  <Button onClick={handleSend}>Finish Archiving</Button>
                  <Button onClick={handleBackdropClick}>Go back to Document</Button>
                </Footer>
            </Card>
        </Root>
    );
}

export default Popup;

