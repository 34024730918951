import React from "react";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { saveProfile, getProfileInfo } from "../../services/Endpoints";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

const ManageUserAccess = (props) => {
  const [addNewUserModelOpen, setAddNewUserModelOpen] = useState(false);
  const [data, setData] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [oneHealthId, setoneHealthId] = useState("");
  const [email, setEmail] = useState("");
  const [apcID, setApcID] = useState("");
  const [userRole, setUserRole] = useState("");
  const [loadDate, setLoadDate] = useState("");
  const [loadingAddUser, setLoadingAddUser] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getPatientData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const validateInput = () => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      userRole !== "" &&
      oneHealthId !== ""
    ) {
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    setUserRole("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setoneHealthId("");
    setApcID("");
    setSaveClicked(false);
    setAddNewUserModelOpen(false);
  };
  const handleSave = async () => {
    try {
      setSaveClicked(true);
      var validate = validateInput();
      if (validate) {
        setLoadingAddUser(true);

        const date = new Date();
        const cstTime = date
          .toLocaleString("en-US", {
            timeZone: "America/Chicago",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            // hour: "2-digit",
            // minute: "2-digit",
            // hour12: true,
          })
          .replace(/\//g, "-");
        console.log("2wedate", cstTime, userRole);

        let data = {
          firstName: firstName,
          lastName: lastName,
          id: oneHealthId,
          oneHealthId: oneHealthId,
          apcId: apcID,
          useremail: email,
          role: userRole,
          datasecurity: "example",
          organization: "optum",
          loadDate: isEdit ? loadDate : cstTime,
        }
        console.log(email);
        let response = await saveProfile(data);
        setLoadingAddUser(false);
        setIsEdit(false);
        handleCloseModal();
        getPatientData();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditThisUser = (record) => {
    setEmail(record.useremail);
    setApcID(record.apcId);
    setUserRole(record.role);
    setFirstName(record.firstName);
    setLastName(record.lastName);
    setLoadDate(record.loadDate);
    setoneHealthId(record.oneHealthId);
  };

  const getPatientData = async () => {
    try {
      const response = await getProfileInfo();
      console.log(response);
      console.log("resposne23", response.data);

      const tempStore = response.data.map((item) => {
        item = {
          firstName: item.firstName,
          lastName: item.lastName,
          fullName: item.firstName + " " + item.lastName,
          oneHealthId: item.oneHealthId,
          apcId: item.apcId,
          useremail: item.useremail,
          role: item.role,
          organization: item.organization,
          loadDate: item.loadDate,
          thisUserDetail: item,
        };
        return item;
      });
      console.log("dummyData", tempStore);
      setData(tempStore);
    } catch (e) {
      console.log(e);
    }
  };

  const columns1 = [
    {
      label: "User Name",
      id: "fullName",
      key: "fullName",
      format: (fullname) => {
        return (
          <div style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{fullname}</div>
        );
      },
    },

    {
      label: "Email",
      id: "useremail",
      key: "useremail",
      minWidth: "80px",
      format: (email) => {
        return (
          <div style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{email}</div>
        );
      },
    },

    {
      label: "User Role",
      id: "role",
      key: "role",
      format: (value) => {
        const roleColor = {
          "Demo User": "#155C8E",
          "Devops Admin": "#15A796",
          "Quality Coordinator": "#C72887",
          "Client Manager": "#8061BC",
        };

        return (
          <div style={{ display: "flex", alignItems: "center", fontFamily: "Optum Sans, Helvetica, Arial" }}>
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: roleColor[value],
                marginRight: "5px",
              }}
            ></div>
            {value}
          </div>
        );
      },
    },

    {
      label: "OHID",
      id: "oneHealthId",
      key: "oneHealthId",
      format: (ohid) => {
        return (
          <div style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{ohid}</div>
        );
      },
    },

    {
      label: "Edit User Detail ",
      id: "thisUserDetail",
      key: "thisUserDetail",
      format: (record) => {
        //console.log("recorded", record);
        return (
          <EditIcon
            onClick={() => {
              setIsEdit(true);
              handleEditThisUser(record);
              setAddNewUserModelOpen(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <div style={{ padding: "40px 48px" }}>
      <div
        style={{
          textAlign: "end",
          justifyContent: "end",
          alignContent: "end",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setAddNewUserModelOpen(true);
          }}
          sx={{
            fontFamily: 'Optum Sans, Helvetica, Arial'
          }}
        >
          Add New
        </Button>
      </div>

      <div >
        <Paper sx={{ overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 520 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns1.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{
                        width: 360,
                        borderRight: "1px solid #CBCCCD",
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#002677",
                        minWidth: column.minWidth,
                        fontFamily: "Optum Sans, Helvetica, Arial",
                        zIndex: "inherit"
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns1.map((column) => {
                          const value = row[column.id];
                          // console.log("value", value, column.id);

                          return (
                            <TableCell
                              style={{
                                width: 360,
                                borderRight: "1px solid #CBCCCD",
                              }}
                              key={column.id}
                            >
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: data.length },
            ]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <Modal
          open={addNewUserModelOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ width: "800px", display: "flex", margin: "auto" }}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "900px",
              height: "600px",
              margin: "auto",
              padding: "20px",
            }}
          >
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="info">
                Adding a New User to the Application!
              </Alert>
            </Stack>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    padding: "5px",
                    marginRight: "50px",
                    marginLeft: "30px",
                  }}
                >
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    First Name:<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    style={{
                      width: "300px",
                    }}
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    id="outlined-basic"
                    // placeholder="First Name"
                    variant="outlined"
                  />
                  {saveClicked && firstName === "" && (
                    <label style={{ color: "red", fontWeight: 500 }}>
                      This field cannot be empty*
                    </label>
                  )}
                </div>
                <div style={{ padding: "5px" }}>
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    Last Name:<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    style={{
                      width: "300px",
                    }}
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    id="outlined-basic"
                    // placeholder="Last Name"
                    variant="outlined"
                  />
                  {saveClicked && lastName === "" && (
                    <label style={{ color: "red", fontWeight: 500 }}>
                      This field cannot be empty*
                    </label>
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    padding: "5px",
                    marginRight: "50px",
                    marginLeft: "30px",
                  }}
                >
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    One Health ID:<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    style={{
                      width: "300px",
                    }}
                    value={oneHealthId}
                    onChange={(e) => {
                      setoneHealthId(e.target.value);
                    }}
                    id="outlined-basic"
                    // placeholder="One Health ID"
                    variant="outlined"
                  />
                  {saveClicked && oneHealthId === "" && (
                    <label style={{ color: "red", fontWeight: 500 }}>
                      This field cannot be empty*
                    </label>
                  )}
                </div>
                <div style={{ padding: "5px" }}>
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  >
                    Email:<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    style={{
                      width: "300px",
                    }}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="outlined-basic"
                    // placeholder="Email"
                    variant="outlined"
                  />
                  {saveClicked && email === "" && (
                    <label style={{ color: "red", fontWeight: 500 }}>
                      This field cannot be empty*
                    </label>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <div
                  style={{
                    padding: "5px",
                    marginRight: "50px",
                    marginLeft: "30px",
                  }}
                >
                  <label
                    style={{
                      fontWeight: 700,
                      fontSize: 16,
                      marginBottom: "20px",
                    }}
                  >
                    User Role:<span style={{ color: "red" }}>*</span>
                  </label>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userRole}
                      //  label="Age"
                      style={{
                        width: "300px",
                      }}
                      onChange={(e) => {
                        setUserRole(e.target.value);
                      }}
                    >
                      <MenuItem value="Devops Admin">Devops Admin</MenuItem>
                      <MenuItem value="Client Manager">Client Manager</MenuItem>
                      <MenuItem value="Quality Coordinator">
                        Quality Coordinator
                      </MenuItem>
                      <MenuItem value="Demo User">Demo User</MenuItem>
                    </Select>
                    {saveClicked && userRole === "" && (
                      <label style={{ color: "red", fontWeight: 500 }}>
                        This field cannot be empty*
                      </label>
                    )}
                  </FormControl>
                </div>
              </div>
            </Box>
            <div
              style={{
                marginTop: "50px",
                width: "100%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {loadingAddUser && (
                  <div>
                    <LinearProgress />{" "}
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert
                        icon={
                          <CircularProgress
                            color="secondary"
                            style={{ width: "25px", height: "25px" }}
                          />
                        }
                        severity="success"
                      >
                        Updating Data!
                      </Alert>
                    </Stack>
                  </div>
                )}
              </Box>
            </div>
            <div
              style={{
                marginTop: loadingAddUser ? "100px" : "150px",
                width: "100%",
                textAlign: "end",
              }}
            >
              <Button
                style={{ backgroundColor: "#f5f5f5eb", marginRight: "30px" }}
                startIcon={<CloseIcon style={{ color: "red" }} />}
                variant="outlined"
                onClick={handleCloseModal}
                sx={{
                  fontFamily: 'Optum Sans, Helvetica, Arial'
                }}
              >
                Close
              </Button>
              <Button
                style={{ backgroundColor: "#f5f5f5eb" }}
                startIcon={<SaveIcon />}
                variant="outlined"
                onClick={handleSave}
                sx={{
                  fontFamily: 'Optum Sans, Helvetica, Arial'
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ManageUserAccess;
