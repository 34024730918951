import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as OptumLogo } from "../../assets/Optum.svg";
import { storeJWT, storeCredentials, validateChartAIUser, getOHIDToken, fetchSignedToken } from "../../services/Endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import { appInsights } from "../../features/Logger/appinsights";
import LoginFailed from "../Login/LoginFailed"

const Root = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
`;

const LoginPanel = styled.div`
  width: 350px;
  // box-shadow: 0px 2px 12px rgba(131, 144, 175, 0.3);
  box-shadow: 0px 4px 12px 0px rgba(25, 25, 26, 0.16);
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  font-weight: 400;

  & svg:first-child {
    padding-bottom: 32px;
  }

  & svg {
    padding-bottom: 12px;
    margin: 0;
  }
`;

const ChartAILogo = styled.div`
  font-size: 32px;
  line height: 44px;
  font-weight: 700;
  color: #002677;
  padding-bottom: 12px;
  `

const Message = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  Color: #323334;
  `

const LoginButton = styled.button`
  color: #ffffff;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 32px;
  font-family: "Optum Sans", Helvetica, Arial;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 24px;
  background: #002677;
  border-radius: 999px;
  overflow: hidden;
  border: 1px #002677 solid;
  font-weight: 700;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #001D5B;
    border-color: #001D5B;
  }
`;

const Error = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: red;
  margin: 0;
  margin-top: -30px;
  padding: 10px 0;
  `

const Disclaimer = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #323334;
  `

const Register = styled.a`
  color: #0C55B8;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
  margin: 0 5px;

  &:active {
    color: #0C55B8;
  }

  &:hover {
    color: #003A8D;
    text-decoration: none;
  }
  `

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 24px 24px 24px;

  & > div {
    display: inline-block;
  }
`

const Copyright = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #323334;

    font-family: "Optum sans";
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
`

const Links = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;

    font-family: "Optum sans";
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    decoration: underline;

    & a {
      color: #0C55B8;
    }

    & a:active {
      color: #003A8D;
    }
  
    & a:hover {
      color: #003A8D;
      text-decoration: none;
    }
`

const Divider = styled.div`
  color: #4B4D4F;
  padding: 0 8px;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Optum Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
  `

function Login({ setLoggedIn }) {
  const navigate = useNavigate();
  const [code, setCode] = useState();
  const [tokenData, setTokenData] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const providerDetails = JSON.parse(window.sessionStorage.getItem("providerDetails")) || {};
  const [showUnauthorizedError, setShowUnauthorizedError] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 25 * 60 * 1000); // 5 minutes in milliseconds  

    return () => clearInterval(interval);
  }, []);


  const handleRedirectForLogin = () => {
    window.location.href =
      "https://" +
      process.env.REACT_APP_OHID_HOSTNAME +
      "/oidc/authorize?pfidpadapterid=OptumIdOIDCStage&client_id=" +
      process.env.REACT_APP_OHID_CLIENTID +
      "&response_type=code&scope=openid+profile+email&redirect_uri=" +
      window.location.origin;
  };

  useEffect(() => {
    if (userData === null) {
      setErrorMessage('LOGIN FAILED');
      setShowUnauthorizedError(true);
      setLoading(false);
      return;
    }

    if (!userData) return;

    console.log("GOT PROFILE: ", userData);

    storeJWT(tokenData.id_token);
    storeCredentials(userData);
    navigate('/Patientlist');
    setLoggedIn(true);
  }, [userData])

  useEffect(() => {
    if (tokenData === null) {
      setErrorMessage("LOGIN FAILED");
      setShowUnauthorizedError(true);
      setLoading(false);
      return;
    }

    if (!tokenData) return;

    console.log("GOT TOKEN: ", tokenData);

    fetchSignedToken(tokenData.OHIDAccessToken, (value) => {
      if (value) validateChartAIUser(tokenData.preferred_username || "", (val) => {
        setUserData(val);
        console.log('val', val, Object.keys(val).length, val === "", typeof val);
        loginTracking(val);
        if (Object.keys(val).length === 0) {
          setLoading(false);
          setShowUnauthorizedError(true);
        }
      })
    });
  }, [tokenData])

  useEffect(() => {
    if (!code) return;
    console.log("GOT CODE: ", code);

    setLoading(true);
    getOHIDToken(code, setTokenData);
  }, [code])

  useEffect(() => {
    if (!queryParams.has('code')) return;

    setCode(queryParams.get("code"));
  }, [])

  const loginTracking = (val) => {
    appInsights.trackEvent({
      name: "customLogs",
      properties: {
        userid: val.id,
        type: "login",
        file: "AppLayout",
        role: val.role,
      },
    });
  };
  console.log('provider', providerDetails);

  return (
    <Root>
      <LoginPanel>
        <OptumLogo alt='Optum Logo' />
        <ChartAILogo>Chart AI</ChartAILogo>
        <Message>Login to view your gap analysis</Message>

        {
          loading
            ?
            <CircularProgress />
            :
            showUnauthorizedError ? <LoginFailed unknownUser={true} onlySmallScreen={false} /> :
              <LoginButton onClick={() => { handleRedirectForLogin(); }}          >
                Log in with One Healthcare ID
              </LoginButton>
        }

        {
          errorMessage
            ?
            <Error>{errorMessage}</Error>
            :
            null
        }

        <Disclaimer>
          Don't have a One Healthcare ID?
          <Register href={'https://' + process.env.REACT_APP_OHID_RegistrationLink} target='_blank'>
            Register here
          </Register>
        </Disclaimer>
      </LoginPanel>

      <Footer>
        <Copyright>
          © 2024 Optum, Inc. All Rights Reserved
        </Copyright>
        <Divider>|</Divider>
        <Links>
          <a href='mailto:chartai_support@optum.com'>Contact Us</a>
        </Links>
      </Footer>
    </Root>
  );
}

export default Login;
