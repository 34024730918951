import React, { useState, useEffect } from 'react';
import CareGapsControl from './CareGapsControl';
import styled from 'styled-components';
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Comment from '../../assets/Comment.svg'
import ArchivalPopup from '../widgets/ArchivalPopup.js';

const Root = styled.div`
  position: absolute;
  top: 25px;
  bottom: 0px;
  left: 50%;
  right: 20px;  
  overflow: hidden;
`

const Title = styled.h1`
    color: #002677;
    font-size: 26px;
    font-weight: 700;
    margin-top: 0px;
    `

const Member = styled.div`
    color: #323334;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;

    label {
      font-weight: bold;
    }
    `

const LoadingText = styled.div`
    display: inline-block;
    width: 120px;
    height: 1em;
    vertical-align: text-bottom;
    border-radius: 3px;
    cursor: progress; 
    background: 
      linear-gradient(0.25turn, transparent, #EEE, transparent),
      linear-gradient(#DDD, #DDD),
      radial-gradient(38px circle at 19px 19px, #DDD 20%, transparent 40%),
      linear-gradient(#DDD, #DDD);  
    background-repeat: no-repeat;
    background-size: 120px 250px, 120px 180px, 125px 100px, 125px 30px; 
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
    animation: loading 3s infinite;

    @keyframes loading {  
      to {
        background-position: 120px 0, 0 0, 0 190px, 0px 195px;
      }
    }
`

const LoadingNumber = styled.div`
    display: inline-block;
    width: 1.5em;
    height: 1em;
    vertical-align: text-bottom;
    border-radius: 3px;
    cursor: progress; 
    background: 
      linear-gradient(0.25turn, transparent, #EEE, transparent),
      linear-gradient(#DDD, #DDD),
      radial-gradient(38px circle at 19px 19px, #DDD 20%, transparent 40%),
      linear-gradient(#DDD, #DDD);  
    background-repeat: no-repeat;
    background-size: 120px 250px, 120px 180px, 125px 100px, 125px 30px; 
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
    animation: loading 3s infinite;

    @keyframes loading {  
      to {
        background-position: 120px 0, 0 0, 0 190px, 0px 195px;
      }
    }
`

const Demographics = styled.div`
    color: #323334;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
`

const ArchiveButtonGrey = styled.button`
  display: inline-block;
  position: relative;
  background-color: #E5E5E6;
  border: solid 1px #3C4142;
  border-radius: 999px;
  color: #3C4142;
  padding: 8px 16px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  float: right;
`
const ArchiveButtonInactive = styled.button`  
  display: inline-block;  
  background-color: #F3F3F3;  
  border: solid 1px #F3F3F3;  
  border-radius: 999px;  
  color: #6d6f70;  
  padding: 8px 24px;  
  font-size: 18px;  
  line-height: 24px;  
  font-weight: 700;  
  float: right;  ;   
`;  

const ArchiveButtonBlue = styled.button`
  display: inline-block;
  font-family: Optum Sans, Helvetica, Arial;
  background-color: #002677;
  border: solid 1px #002677;
  border-radius: 999px;
  color: #FFFFFF;
  padding: 8px 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  float: right;
  &:hover {  
    background-color: #001D5B;  
    border: solid 1px #001D5B;
  }
`
const Popup = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000070;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;

    cursor: pointer;

    z-index: 99999;

    display: ${props => props.showing ? "block" : "none"};
`

function CareGapPanel({ setNewLocation, careGapData, setEvidenceClicked }) {
  const [finishedDocument, setFinishedDocument] = useState(false);
  const [showArchivalPopup, setShowArchivalPopup] = useState(false);
  const [newFileName, setNewFileName] = useState("");

  const navigate = useNavigate();

  const notFinishedClick = () => {
    alert("Please choose a decision for each care gap before archiving");
  };

  const finishedClick = () => {
    let patientData = careGapData.last_name + "_" + careGapData.first_name + "_" + careGapData.dob;
    let careGapIDs = "";
    for (let i = 0; i < careGapData.care_gap_result.length; i++) {
      if (careGapData.care_gap_result[i].gap_status === "Close") {
        let id = careGapData.care_gap_result[i].measure_id;
        careGapIDs = careGapIDs + "_" + id;
      }
    }
    setNewFileName(patientData + careGapIDs + ".pdf");
    setShowArchivalPopup(true);
  };

  useEffect(() => {
    let doneDocument = true;
    for (let i = 0; i < careGapData?.care_gap_result.length; i++) {
      let status = (careGapData?.care_gap_result[i].gap_status);
      if (status === "") {
        doneDocument = false;
      }
    }
    setFinishedDocument(doneDocument);
  }, [careGapData])

  return (
    <Root>

      <ArchivalPopup ArchivalPopup showing={ showArchivalPopup } setShowing={setShowArchivalPopup} careGapData={careGapData} newFileName={newFileName}/>
      {!finishedDocument && <ArchiveButtonInactive onClick={notFinishedClick}>Archive</ArchiveButtonInactive>}
      {finishedDocument && <ArchiveButtonBlue onClick={finishedClick}>Archive</ArchiveButtonBlue>}

      <Member>
        <label>Member Details: </label>
      </Member>
      <Demographics>
        {careGapData?.first_name}
        &nbsp;
        {careGapData?.last_name}
        &nbsp;|&nbsp;
        {careGapData?.dob}
      </Demographics>
      <Member>
        <label>
          Care gap and analysis
        </label>
      </Member>
      <Member>
        <label>
          {careGapData?.care_gap_result.length || <LoadingNumber />}
          &nbsp;
          Care gaps found for
        </label>
        &nbsp;
        {careGapData?.first_name}
        &nbsp;
        {careGapData?.last_name}
      </Member>

      <CareGapsControl 
        setNewLocation={setNewLocation} 
        careGapData={careGapData} 
        setEvidenceClicked={setEvidenceClicked} 

        setFinishedDocument={setFinishedDocument}
      />
    </Root>
  );
}

export default CareGapPanel;
