import React from 'react';
import styled from 'styled-components';

const Root = styled.div`

`

function Settings() {
  return (
    <Root>
        settings
    </Root>
  );
}

export default Settings;
