import React, { useState, useEffect } from 'react';
import CloseX from '../../assets/CloseX.svg'
import styled from 'styled-components';
import { sendFeedback } from '../../services/Endpoints';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import '../../fonts/OptumSans.css';

const Root = styled.div`
font-family: Optum Sans, Helvetica, Arial;
font-style: normal; 
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #00000070;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;

    cursor: pointer;

    z-index: 99999;

    display: ${props => props.showing?"block":"none"};
`

const Card = styled.div`
position: fixed;  
  top: 50%;  
  left: 50%;  
  transform: translate(-50%, -50%); 
    width: 45%;
    padding: 16px;
    gap: 16px;
    flex-wrap: wrap;
    border-radius: 4px;
    background: var(--modal-color-background-base, #FFF);
    /* modal/elevation/medium */
    box-shadow: 0px 4px 12px 0px rgba(25, 25, 26, 0.16);
    cursor: default;


`

const StyledCheckbox = styled(Checkbox)`  
  color: #4B4D4F !important;  
  
  &.Mui-checked {  
    color: #0C55B8  !important;  
  }  
`;

const Header = styled.div`
    display: flex;
    margin-left: 97%;
`
const Body = styled.div`
font-family: Optum Sans, Helvetica, Arial;
font-style: normal; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;
`
const Footer = styled.div`
font-family: Optum Sans, Helvetica, Arial;
font-style: normal; 
    margin-top: 0px;
    display: flex;  
    justify-content: flex-end;  
    align-items: center;
    padding-top: 16px;
`

const Title = styled.div`  
  font-family: Optum Sans, Helvetica, Arial;
  font-style: normal;   
  color: var(--modal-color-text-heading, #002677);  
  font-size: 26px;   
  font-weight: 700;  
  line-height: 32px; /* 123.077% */  
  padding: 8px 0px 8px 0px;  
`  

const SubtitleHolder = styled.div`
font-family: Optum Sans, Helvetica, Arial;
font-style: normal; 
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px 0px 0px 0px;
`

const Subtitle = styled.div`
font-family: Optum Sans, Helvetica, Arial;
font-style: normal; 
    color: #323334;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 120% */
`

const TextHeading = styled.div`
font-family: Optum Sans, Helvetica, Arial;
    color: var(--app-adv-color-form-field-text-label, #323334);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 125% */
    margin-top: 16px;

`
const TextsUBHeading = styled.div`
font-family: Optum Sans, Helvetica, Arial;
    color: #4B4D4F;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 125% */
    

 
`

const TextBox = styled.textarea`
font-family: Optum Sans, Helvetica, Arial;
font-style: normal;
    height: 100px;
    padding: 8px 12px 0px 12px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #4B4D4F;
    resize: none;
    &:hover {
      border-color: #0C55B8;
    }
`
const Button = styled.button`
font-family: Optum Sans, Helvetica, Arial;
font-style: normal; 
    display: flex;
    width: Hug (86px);
    background-color: #002677;
   /* border: solid 1px #002677;*/
    border-radius: 999px;
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    cursor: pointer;
    margin-bottom: 4px;
    line-height : 24px;
    border: none;
    &:hover {  
      background-color: #001D5B;  
    }
`

const BackButton = styled.button`
font-family: Optum Sans, Helvetica, Arial;
font-style: normal; 
    display: flex;
    background-color: #FFFFFF ;
    border: solid 1px #002677;
    border-radius: 999px;
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 700;
    color: #002677;
    cursor: pointer;
    margin-bottom: 4px;
    line-height: 24px;
    &:hover{
      background-color: #F3F3F3;
      color: #001D5B;
      border-color: #001D5B;
    }
`

const ErrorBox = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    gap: 4px;
`

const RedText = styled.text`
    color: var(--semantic-danger-70, #C40000);
    font-feature-settings: 'clig' off, 'liga' off;

    /* desktop/body/3/a */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
`
const SelectChoiceBox = styled.div`
    border-radius: 8px;
    border: 1px solid var(--neutral-20, #000000);
    background: var(--neutral-0, #FFF);
    width: 320px;
    height: 40px;
    flex-shrink: 0;
    display: flex;
`

const SelectChoiceDropdown = styled.div`
    margin-top: 10px;
`

const SelectChoiceText = styled.div`
    color: var(--neutral-90, #323334);
    display: inline;
    width: 280px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 133.333% */
    margin-left: 10px;
    margin-top: 8px;
`

const SelectChoiceDropdownBox = styled.div`
    border-radius: 8px;
    border: 1px solid var(--neutral-20, #000000);
    background: var(--neutral-0, #FFF);
    width: 320px;
    height: 200px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
`
const OtherChoiceHolder = styled.div`
    display: flex;
    border-radius: 8px;
    width: 320px;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    background: var(--neutral-0, #FFF);
    flex-drection: row;
    margin-bottom: 5px;
`
const OtherChoiceBox = styled.div`
    width: 268px;
    height: 30px;
    margin-top: 8px;
    margin-left: 10px;
    border-radius: 8px;
    border: 1px solid var(--neutral-20, #E5E5E6);
    background: #F3F3F3;
`
const OtherChoiceBoxSelected = styled.div`
    width: 268px;
    height: 30px;
    margin-top: 8px;
    margin-left: 10px;
    border-radius: 8px;
    border: 1px solid var(--neutral-20, #E5E5E6);
    background: #90fff06b;
`

const SelectChoiceDropdownText = styled.div`  
  display: flex;  
  align-items: center;  
  justify-content: space-between;  
  color: var(--neutral-90, #323334);  

  font-feature-settings: 'clig' off, 'liga' off;  
  font-size: 16px;  
  font-style: bold;  
  font-weight: 700;  
  line-height: 18px;  
  margin-left: 10px;  
  margin-top: 5px;  
`;  

const SelectedChoiceDropdownText = styled.div`  
  display: flex;  
  align-items: center;  
  justify-content: space-between;  
  color: var(--neutral-90, #90fff06b);  
  font-feature-settings: 'clig' off, 'liga' off;  
  font-size: 16px;  
  font-style: bold;  
  font-weight: 700;  
  line-height: 18px;  
  margin-left: 10px;  
  margin-top: 5px;  
`;


// ***********************************************

function PopupGapAction({ showing, setShowing, userAction, gapData, careGapData }) {
    const [commentValue, setCommentValue] = useState("No comment");
    const [thumbValue, setThumbValue] = useState(-1);
    const [choiceErrorShowing, setChoiceErrorShowing] = useState(false);
    const [errorShowing, setErrorShowing] = useState(false);
    const [selectedChoice, setSelectedChoice] = useState("Select an option");
    const [selectedChoiceList, setSelectedChoiceList] = useState([]);
    const [comment, setComment] = useState("");
    const [maxCharacters, setMaxCharacters] = useState(250);

    const changeText = (e) => {

     if(comment.length<250) {
          setCommentValue(e.target.value);
        setComment(e.target.value);
      }
      }

      const chooseOption = (choice) => {
   
        if(choice === "" || choice === undefined) return;
       
        const val = choice;
           setSelectedChoice(val);
           // Check if the string exists in the array  
         const index = selectedChoiceList.indexOf(val);  
         // If the string exists, remove it from the array  
         if (index !== -1) {  
           setSelectedChoiceList((prevArray) => prevArray.filter((item) => item !== val));  
         }  
         // If the string does not exist, add it to the array  
         else {  
           setSelectedChoiceList((prevArray) => [...prevArray, val]);  
         }  
           // setDropdownActive(false);
           if(choice !== "Select an option") {
             setChoiceErrorShowing(false);
           }
         }

    const handleBackdropClick = (e) => {
        setShowing(false);
        setErrorShowing(false);
        setThumbValue(-1);
        setCommentValue("No comment");
        setComment("");
        document.getElementById('commentspace').value = "";
        setSelectedChoiceList([]);
        
    }

    const handleCardClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const handleThumbUp = () => {
        setErrorShowing(false);
        if(thumbValue === 1) {
          setThumbValue(0);
        } else {
          setThumbValue(1);
        }
      }
  
      const handleThumbDown = () => {
        setErrorShowing(false);
        if(thumbValue === -1) {
          setThumbValue(-1);
        } else {
          setThumbValue(-1);
        }
      }

    const handleSend = () => {
        let user = JSON.parse(window.sessionStorage.getItem("UserOnehealthID"));
        if(user === null) {
          user = "localhost";
        }
        var id = careGapData.file_id + "-" + gapData.measure_id + "-Action"
        var recommended = gapData.recommendation.toLowerCase();
        setErrorShowing(false);

        if(recommended === "not enough evidence") {
            recommended = "Leave Open";
        }

        if(thumbValue === 1){
            let data = {
                page_id: id,
                file_id: careGapData.file_id,
                care_gap_name: gapData.measure_name,
                feedback_type: "Action",
                user: user,
                first_name: careGapData.first_name,
                last_name: careGapData.last_name,
                analysis: gapData.analysis,
                evidence: gapData.evidence,
                recommendation: recommended,
                actual: userAction,
                comment: commentValue,
                thumbs_up_down: "Positive",
                category: selectedChoiceList
            }

            sendFeedback(data);

            setShowing(false);
            setThumbValue(-1);
            setCommentValue("No comment");
            setComment("");
            document.getElementById('commentspace').value = "";
            setSelectedChoiceList([]);
        }

        if(thumbValue === -1){
            let data = {
                page_id: id, 
                file_id: careGapData.file_id,
                care_gap_name: gapData.measure_name,
                feedback_type: "Action",
                user: user,
                first_name: careGapData.first_name,
                last_name: careGapData.last_name,
                analysis: gapData.analysis,
                evidence: gapData.evidence,
                recommendation: recommended,
                actual: userAction,
                comment: commentValue,
                thumbs_up_down: "Negative",
                category: selectedChoiceList
            }
            
            sendFeedback(data);

            setShowing(false);
            setThumbValue(-1);
            setCommentValue("No comment");
            setComment("");
            document.getElementById('commentspace').value = "";
            setSelectedChoiceList([]);
        }

        if(thumbValue === 0 ) {
            // setErrorShowing(true);

            //***** Remove this part if we ever restore thumbs
            let data = {
              page_id: id, 
              file_id: careGapData.file_id,
              care_gap_name: gapData.measure_name,
              feedback_type: "Action",
              user: user,
              first_name: careGapData.first_name,
              last_name: careGapData.last_name,
              analysis: gapData.analysis,
              evidence: gapData.evidence,
              recommendation: recommended,
              actual: userAction,
              comment: commentValue,
              thumbs_up_down: "None",
              category: selectedChoiceList
          }
          
          sendFeedback(data);

          setShowing(false);
          setThumbValue(-1);
          setCommentValue("No comment");
          setComment("");
          document.getElementById('commentspace').value = "";       
          setSelectedChoiceList([]);                 
          //******
        }
    }

    useEffect(()=>{
        setShowing(showing)
    }, [showing])

    return (
        <Root showing={ showing } onClick={ handleBackdropClick }>
            <Card onClick={ handleCardClick }>
            <div style={{display:'flex'}}>  
                  <Title>Leave <span style={{ textTransform: "lowercase" }}>feedback</span> </Title>  
                  <img style={{cursor:'pointer', marginLeft: 'auto'}} src={CloseX} alt="CloseX" onClick={handleBackdropClick}/>  
              </div>
                <Body>
                  <TextHeading>Why did you select the non-recommended option?<RedText> *</RedText></TextHeading>
                  <TextsUBHeading >Select all that apply </TextsUBHeading>
                  <FormGroup  style={{gap:'8px',padding:'0px 8px 0px 8px',width:'50%'}}>
                      <FormControlLabel onClick={(e)=>chooseOption(e.target.outerText)} 
                      control={<StyledCheckbox onClick={(e)=>chooseOption(e.target.value)} value="Missing data" checked = {selectedChoiceList.includes("Missing data")}/>} label="Missing data" value="Missing" />
                      <FormControlLabel onClick={(e)=>chooseOption(e.target.outerText)} 
                      control={<StyledCheckbox onClick={(e)=>chooseOption(e.target.value)} value="Incorrect evidence" checked = {selectedChoiceList.includes("Incorrect evidence")} />} label="Incorrect evidence" />
                      <FormControlLabel onClick={(e)=>chooseOption(e.target.outerText)} 
                      control={<StyledCheckbox onClick={(e)=>chooseOption(e.target.value)} value="Data quality was not good" checked = {selectedChoiceList.includes("Data quality was not good")} />} label="Data quality was not good" />
                      <FormControlLabel onClick={(e)=>chooseOption(e.target.outerText)} 
                      control={<StyledCheckbox onClick={(e)=>chooseOption(e.target.value)} 
                      value="Other" 
                      checked = {selectedChoiceList.includes("Other")} 
                      />} 
                    label="Other"
                      />
                          
                      
                    </FormGroup>
                  {/* <SubtitleHolder>
                    <Subtitle>Does the care gap provide good analysis/evidence<RedText>*</RedText></Subtitle>
                    {(thumbValue === 0 || thumbValue === -1)&& <img src = {thumbUpEmpty} alt="thumbUpEmpty" onClick={handleThumbUp} width={24} height={24}/>}
                    {thumbValue === 1 && <img src = {thumbUpFilled} alt="thumbUpFilled" onClick={handleThumbUp} width={24} height={24}/>}
                    {(thumbValue === 0 || thumbValue === 1)&& <img src = {thumbDownEmpty} alt="thumbDownEmpty" onClick={handleThumbDown} width={24} height={24}/>}
                    {(thumbValue === -1)&& <img src = {thumbDownFilled} alt="thumbDownFilled" onClick={handleThumbDown} width={24} height={24}/>}
                  </SubtitleHolder> */}
                  {/* <ErrorBox>
                    {(errorShowing) && <img src = {error} alt="error" width={18} height={18}/>}
                    {(errorShowing) && <RedText>Selection required</RedText>}
                  </ErrorBox> */}
                  <TextHeading>Enter any additional comments below</TextHeading>
                  <TextsUBHeading>A response is optional. </TextsUBHeading>
                  <TextBox id='commentspace' onChange={changeText} maxLength={250}></TextBox>
                  <TextsUBHeading>{maxCharacters - comment.length} characters remaining</TextsUBHeading>
                </Body>
                <Footer>
                <Button 
                    style={{ backgroundColor: selectedChoiceList.length ===0 && '#F3F3F3', 
                    cursor: selectedChoiceList.length ===0 ? 'default' : 'pointer',
                     color:  selectedChoiceList.length ===0 && "#6D6F70" }}
                     onClick={handleSend} disabled = { selectedChoiceList.length ===0 } >
                      Submit
                      </Button>
                </Footer>
            </Card>
        </Root>
    );
}

export default PopupGapAction;
