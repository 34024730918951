import React from "react";
import ReactDOM from "react-dom/client";
import { pdfjs } from "react-pdf";
import worker from "pdfjs-dist/webpack";
import "./index.css";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
pdfjs.GlobalWorkerOptions.workerSrc = worker;

console.log("BUILD: 8/8");

root.render(
  <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
  </React.StrictMode>
);
